import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import React from 'react';
import { Box, IconButton } from '@mui/material';

const Support = () => {
  
  
    const handleWhatsAppClick = () => {
      window.open('https://wa.me/9237301338', '_blank'); // Opens WhatsApp link
    };
  
    
    return (
      <Box sx={{ }}>
        
          {/* Floating WhatsApp Button */}
        <IconButton
          onClick={handleWhatsAppClick}
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            backgroundColor: '#25D366',
            color: '#fff',
            width: 56,
            height: 56,
            boxShadow: 3,
            '&:hover': {
              backgroundColor: '#1DA955',
            },
          }}
        >
          <WhatsAppIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </Box>
    );
  };
  
  export default Support;