import React from 'react';
import {
  Typography,
  Button,
  Container,
  CardMedia,
  CardContent,
  Box,
  Grid,
  Card,
} from '@mui/material';
import Video from '../../../Video/Ev9/ev9.mp4';
import DriveEtaIcon from '@mui/icons-material/DriveEta'; 
import DescriptionIcon from '@mui/icons-material/Description'; 
import Showinterest from '../../../show interest/Container/showinterestCon';
import Slidingev9 from '../../../Cars/EV9/Component/Slidingev9';
import Contentcarrousel from '../../../Cars/EV9/Component/Contentcarrousel';
import Animationeffect from '../../EV9/Component/Animationeffect'
import Ev9brochure from '../../../Brochure/Ev9/Ev9.pdf'
import ev1 from '../../../Image/Ev9/full1.jpg'
import evfullimage from '../../../Image/Ev9/full2.jpg';
import evcard1 from '../../../Image/Ev9/evcard1.jpg';
import evcard2 from '../../../Image/Ev9/evcard2.jpg';
import evcard3 from '../../../Image/Ev9/evcard3.jpg'
import evcard4 from '../../../Image/Ev9/evcard4.jpg';
import evcard5 from '../../../Image/Ev9/evcard5.jpg';
import evfullimage2 from '../../../Image/Ev9/full3.jpg';
import evcard6 from '../../../Image/Ev9/evcard6.jpg';
import evcard7 from '../../../Image/Ev9/evcard7.jpg';
import evcard8 from '../../../Image/Ev9/evcard8.jpg';
import evfullimage3 from '../../../Image/Ev9/full4.jpg';
import evfullimage4 from '../../../Image/Ev9/full5.jpg';
import Cardssection from './cardssection4';
import Cardssection5 from './cardssection5'
import Cardssection6 from './cardssection6'
import evfullimage5 from '../../../Image/Ev9/full6.jpg';
import CallIcon from '@mui/icons-material/Call';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Faqs from '../../../Cars/EV9/Component/faqs';
import Colorev from './color'
import Wsp from '../../../contact/Component/Floatingwsp';

const styles = {
  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    padding: "20px",
    flexWrap: "wrap", 
  },
  card: {
    maxWidth: 500,
    marginBottom: "20px",
  },
  cardMedia: {
    height: 200,
    width: "100%",
    objectFit: "cover",
  },
  title: {
    fontWeight: "bold",
  },
  description: {
    color: "#555",
  },
};


const Ev9 = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960, // Tablet breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    nextArrow: <button style={{ right: '-25px', position: 'absolute' }}>→</button>,
    prevArrow: <button style={{ left: '-25px', position: 'absolute' }}>←</button>,
  };

  const cards = [
    {
      image: evcard1,
      title: "Electric-Global Modular Platform ( E-GMP )",
      description: "E-GMP forms the basis for Kia EV9. It offers revolutionary EV Technology such as 800-volt ultra-fast charging, bi-directional charging capabilities such as Vehicle-to-Load (V2L) and incorporates the latest generation of EV batteries",
    },
    {
      image: evcard2, 
      title: "AWD Powertrain",
      description: "The Kia EV9 comes equipped with twio permanent magnet synchronous motors, one on each axle, with a total combined power output of 28.6 Kw and 700 Nm of torque.",
    },
    {
      image: evcard3, 
      title: "Drive and Terrain Modes",
      description: "The Kia EV9 comes with different drive Modes designed to suit different driver preferences on the road. The terrain modes regulate the torque and transmits the power per wheel selectively ",
    },
  ];

  const cards1 = [
    {
      image: evcard4,
      title: "Vehicle-to-load (V2L)",
      description: "V2L can be divided into two types: V2L inside (socket in the interior) and V2L outside (V2L Connector in the charging socket). Both deliver a maximum output of 3.68 kW which can be used to charge or power electric devices or other electric vehicles",
    },
    {
      image: evcard5, 
      title: "Range: 561 KM",
      description: "Experience the freedom to explore with confidence. The Kia EV9 offers an impressive range, ensuring every journey, whether long or short, is worry-free and filled with unmatched possibilities. ",
    },

  ];

  const cards2 = [
    {
      image: evcard6,
      title: "Kia Connect 2.0",
      description: "Stay connected to your EV9 with the 100 connected car features, like CCNC- Connected Car Navigation Cockpit, Kia Connect Diagnosis for your car, Advanced Total Care System and much more.",
    },
    {
      image: evcard7, 
      title: "Digital Key 2.0",
      description: "Your Digital Key 2.0 can be installed on the smartphone for touch control or remote control, as well as key sharing with family and friends. Enjoy the convenience of locking/unlocking your car even without taking yor smartphone out of your pocket. ",
    },
    {
      image: evcard8, 
      title: "Over-the-Air Software Updates",
      description: "Your EV9 evolves with you. Receive automatic updates to software and features over the air, ensuring that your vehicle is always equipped with the latest technology, enhancements, and performance improvements - all without stepping into a service centre.",
    },
  ];

 
  return (
    <Box sx={{overflowX:'hidden'}}>
        <Box sx={{ position: 'relative', height: '100vh', overflow: 'hidden',}}>
            <video
               playsInline
               autoPlay
               loop
               muted
               preload="auto"
               style={{
                   position: 'absolute',
                   width: '100%',
                   height: '100%',
                   objectFit: 'cover',
               }}
            >
            <source src={Video} type="video/mp4" />
            </video>

        </Box>

        <Wsp/>

        <Box sx={{backgroundColor:'#FFFFFF'}}>
          
            {/* Secord card section */}
            <Box sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={12} md={6}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', boxShadow: 3 ,gap:'15px'}}>
                            <DriveEtaIcon sx={{ fontSize: '40px', color: '#000'  }} />
                            <Typography variant="h6" sx={{ marginTop: '10px',fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold" }}>
                            Register your interest
                            </Typography>
                            <Typography variant="body1" sx={{color:'#848484',fontSize:'13px',fontWeight:700,fontFamily:"KIAbodyLatinbasic_Bold"}}>Fill in the required details by clicking here, We will contact you as soon as possible.</Typography>
                            <Button
                                variant="contained"
                                sx={{
                                  marginTop: '10px',
                                  backgroundColor: "#E50063",
                                  color: 'white',
                                  borderRadius: '8px',
                                  fontFamily:"KIAbodyLatinbasic_Bold",
                                  height: '50px',
                                  '&:hover':{
                                    backgroundColor: "#E50063",
                                  }
                                }}
                                onClick={() => {
                                  const element = document.getElementById("showinterest");
                                  if (element) {
                                    element.scrollIntoView({ behavior: "smooth" });
                                  }
                                }}
                              >
                                Click Here
                            </Button>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', boxShadow: 3,gap:'15px' }}>
                            <DescriptionIcon sx={{ fontSize: '40px', color: '#000' }} />
                                <Typography variant="h6" sx={{ marginTop: '10px',fontWeight:'bold',fontFamily:"KIAbodyLatinbasic_Bold" }}>
                                Download Brochure
                                </Typography>
                                <Typography variant='body1' sx={{color:'#848484',fontSize:'13px',fontWeight:700,fontFamily:"KIAbodyLatinbasic_Bold"}}>Click here to download the Brochure and price list of all new EV9 on your device</Typography>
                                <Button variant="contained" sx={{ marginTop: '10px',backgroundColor:"#E50063",color:'white',borderRadius:'8px',height:'50px',fontFamily:"KIAbodyLatinbasic_Regular",fontWeight:800,
                                  '&:hover': {
                                    backgroundColor: '#E50063',
                                  }
                                  }}
                                onClick={() => {
                                  const link = document.createElement('a');
                                  link.href = Ev9brochure; 
                                  link.download = 'Kia-EV9_brochure.pdf';
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                                }}
                                >
                                Click To Download
                                </Button>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            {/* Sliding ev9 */}
            <Slidingev9/>

            {/* color */}
            <Colorev/>

              {/* Content carrousels */}
              <Contentcarrousel/>

              {/* show interest */}
              <Box id="showinterest">
              <Showinterest/>
              </Box>

              {/* Animation effect */}
              <Animationeffect/>

              {/* 1.Content and full sized image */}
              <Box>
                <Box 
                  sx={{
                    backgroundColor: '#1d1d1d', 
                    color: 'white',
                    textAlign: 'center',
                    py: 6, 
                    px: 3, 
                  }}
                >
                  <Container maxWidth="md">
                    <Typography variant="h3" component="h1" sx={{fontWeight:'bold'}} gutterBottom>
                    Power to your journeys
                    </Typography>
                    <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
                    The EV9's exhilarating performance comes from its high-powered motors, which deliver 282.6 kW power and 700 Nm of <br />
                    torque. Coupled with a high-energy battery that goes from 10% to 80% in just 24 minutes**,<br />
                    this electric is unstoppable, no matter how long the road.
                    </Typography>
                  </Container>
                </Box>

                <Box sx={{ width: '100vw', height: {md:'70vh',sm:'60vh',xs:'40vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height: {md:'70vh',sm:'60vh',xs:'40vh'},
                    backgroundImage: `url(${ev1})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/* 3 card section */}
              <Grid container spacing={4} justifyContent="center" alignItems="center"sx={{ marginLeft:{md:'50px',sm:'20px',xs:'7px'},marginTop:'10px' }}width='90%'>
                  {cards.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card style={styles.card}>
                        <CardMedia
                          component="img"
                          style={styles.cardMedia}
                          image={card.image}
                          alt={card.title}
                        />
                        <CardContent>
                          <Typography variant="h6" sx={{color:'#666666',fontFamily:'Kia-sig',marginTop:'15px',fontSize:{md:'22px',xs:'18px'}}} style={styles.title}>
                            {card.title}
                          </Typography>
                          <Typography style={styles.description} sx={{color:'#666666',fontFamily:'Kia-sig',marginTop:'15px',fontSize:{md:'18px',xs:'16px'}}}>
                            {card.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>

                {/* 2.Full image, content and 3 cards*/}
              <Box>
              <Box 
                  sx={{
                    backgroundColor: '#1d1d1d', 
                    color: 'white',
                    textAlign: 'center',
                    py: 6, 
                    px: 3, 
                  }}
                >
                  <Container maxWidth="md">
                    <Typography variant="h3" component="h1" sx={{fontWeight:'bold'}} gutterBottom>
                    Back on the road before you know it
                    </Typography>
                  </Container>
                </Box>
                <Box sx={{ width: '100vw', height: {md:'100vh',sm:'60vh',xs:'40vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height:  {md:'100vh',sm:'60vh',xs:'40vh'},
                    backgroundImage: `url(${evfullimage})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/* 2nd part : 2 cards */}
              <Grid container spacing={4} justifyContent="center" alignItems="center"sx={{ marginLeft:{md:'50px',sm:'20px',xs:'7px'},marginTop:'10px' }}width='90%'>
                  {cards1.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card style={styles.card}>
                        <CardMedia
                          component="img"
                          style={styles.cardMedia}
                          image={card.image}
                          alt={card.title}
                        />
                        <CardContent>
                          <Typography variant="h6" sx={{color:'#666666',fontFamily:'Kia-sig',marginTop:'15px',fontSize:{md:'22px',xs:'18px'}}} style={styles.title}>
                            {card.title}
                          </Typography>
                          <Typography style={styles.description} sx={{color:'#666666',fontFamily:'Kia-sig',marginTop:'15px',fontSize:{md:'18px',xs:'16px'}}}>
                            {card.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>

              {/* 3.Content and full sized image */}
              <Box>
                <Box 
                  sx={{
                    backgroundColor: '#1d1d1d', 
                    color: 'white',
                    textAlign: 'center',
                    py: 6, 
                    px: 3, 
                  }}
                >
                  <Container maxWidth="md">
                    <Typography variant="h3" component="h1" sx={{fontWeight:'bold'}} gutterBottom>
                    The Strongest Bond
                    </Typography>
                    <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
                    Stay in sync with the EV9 no matter where you are. More than 100 connected car features, dynamic solutions, and <br/>seamless integrations make owning this electric a joyful experience with Kia Connect 2.0.
                   <br />
                    </Typography>
                  </Container>
                </Box>

                <Box sx={{ width: '100vw', height: {md:'100vh',sm:'60vh',xs:'40vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height: {md:'100vh',sm:'60vh',xs:'40vh'},
                    backgroundImage: `url(${evfullimage2})`,
                    backgroundSize:'cover', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/*3rd sec:  3 card section */}
              <Grid container spacing={4} justifyContent="center" alignItems="center"sx={{ marginLeft:{md:'50px',sm:'20px',xs:'7px'},marginTop:'10px' }}width='90%'>
                  {cards2.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Card style={styles.card}>
                        <CardMedia
                          component="img"
                          style={styles.cardMedia}
                          image={card.image}
                          alt={card.title}
                        />
                        <CardContent>
                          <Typography variant="h6" sx={{color:'#666666',fontFamily:'Kia-sig',marginTop:'15px',fontSize:{md:'22px',xs:'18px'}}} style={styles.title}>
                            {card.title}
                          </Typography>
                          <Typography style={styles.description} sx={{color:'#666666',fontFamily:'Kia-sig',marginTop:'15px',fontSize:{md:'18px',xs:'16px'}}}>
                            {card.description}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>

                 {/* 4.Content and full sized image */}
              <Box>
                <Box 
                  sx={{
                    backgroundColor: '#1d1d1d', 
                    color: 'white',
                    textAlign: 'center',
                    py: 6, 
                    px: 3, 
                  }}
                >
                  <Container maxWidth="md">
                    <Typography variant="h3" component="h1" sx={{fontWeight:'bold'}} gutterBottom>
                    Room for life
                    </Typography>
                    <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
                    There's room for everyone and everything in the EV9. A versatile cabin design balances the space for occupants and<br/> belongings, 

                    </Typography>
                  </Container>
                </Box>

                <Box sx={{ width: '100vw', height: {md:'100vh',sm:'60vh',xs:'40vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height: {md:'100vh',sm:'60vh',xs:'40vh'},
                    backgroundImage: `url(${evfullimage3})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/*4th sec:  2 card section */}
              <Cardssection/>
             

              {/* 5.Content and full sized image */}
              <Box>
                <Box 
                  sx={{
                    backgroundColor: '#1d1d1d', 
                    color: 'white',
                    textAlign: 'center',
                    py: 6, 
                    px: 3, 
                  }}
                >
                  <Container maxWidth="md">
                    <Typography variant="h3" component="h1" sx={{fontWeight:'bold'}} gutterBottom>
                    Tech that goes the distance
                    </Typography>
                    <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
                    Designed to be intuitive with cutting-edge yet effortless technology, the EV9 is an inspiring electric.<br />
                    With such futuristic tech, every drive will be a breeze.<br />
                   
                    </Typography>
                  </Container>
                </Box>

                <Box sx={{ width: '100vw', height: {md:'100vh',sm:'60vh',xs:'40vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height:  {md:'100vh',sm:'60vh',xs:'40vh'},
                    backgroundImage: `url(${evfullimage4})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/*5th sec:  2 card section */}
              <Cardssection5/>

                 {/* 6.Content and full sized image */}
              <Box>
                <Box 
                  sx={{
                    backgroundColor: '#1d1d1d', 
                    color: 'white',
                    textAlign: 'center',
                    py: 6, 
                    px: 3, 
                  }}
                >
                  <Container maxWidth="md">
                    <Typography variant="h3" component="h1" sx={{fontWeight:'bold'}} gutterBottom>
                    Safety that’s electrifying.
                    </Typography>
                    <Typography variant="body1" sx={{ lineHeight: 1.6 }}>
                    With over 27 advanced *ADAS functions, the EV9 redefines safety by combining cutting-edge innovation with a focus<br />
                    on both active and passive protection. Engineered to keep everyone in and around the vehicle safe,<br/>
                    it ensures you stay in control, no matter the journey ahead.
                    </Typography>
                  </Container>
                </Box>

                <Box sx={{ width: '100vw', height: {md:'100vh',sm:'60vh',xs:'40vh'}, overflow: 'hidden' }}>
                <Grid
                  container
                  sx={{
                    width: '100%',
                    height:  {md:'100vh',sm:'60vh',xs:'40vh'},
                    backgroundImage: `url(${evfullimage5})`,
                    backgroundSize: 'cover', 
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      color: '#fff',
                      textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
                    }}
                  >

                  </Grid>
                </Grid>
              </Box>
              </Box>

              {/*6th sec:  3 card section */}
             <Cardssection6/>



                {/* Info */}
                <Box sx={{ padding: '50px 0', textAlign: 'center' }}>
                  <Typography 
                    variant="h5" 
                    color="text.secondary" 
                    sx={{ fontWeight: 'bold', mb: 5,fontFamily:'Kia-sig', }}
                  >
                    DETAILED INFORMATION
                  </Typography>

                  <Grid container spacing={4} justifyContent="center">
                   
                    {/* Download Brochure Card */}
                    <Grid item xs={12} md={4}>
                      <Box
                        sx={{
                          bgcolor: '#0b1d28',
                          color: '#fff',
                          padding: '20px',
                          borderRadius: '4px',
                        }}
                      >
                        <PictureAsPdfIcon sx={{ fontSize: 60, color: 'white', mb: 2 }} />
                        <Typography variant="h6" fontWeight="bold" sx={{fontFamily:'Kia-sig'}}>
                          Download Brochure
                        </Typography>
                        <Typography color="white" sx={{ mb: 2,fontFamily:'Kia-sig' }}>
                        Get to know all the facts and figures you need to know about the fully electric vehicle Kia EV9. The World's Most Inspiring Electric. Ever.
                        </Typography>
                        <Button 
                          variant="contained" 
                          color="secondary" 
                          onClick={() => {
                            const link = document.createElement('a');
                            link.href = Ev9brochure; 
                            link.download = 'Kia-EV9_brochure.pdf';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                        >
                          Download
                        </Button>
                      </Box>
                    </Grid>

                    {/* Call Us Card */}
                    <Grid item xs={12} md={4}>
                      <Box>
                        <CallIcon sx={{ fontSize: 60, color: 'black', mb: 2 }} />
                        <Typography variant="h6" fontWeight="bold" fontFamily="Kia-sig">
                          Call us for more information
                        </Typography>
                        <Typography color="text.secondary" sx={{ mb: 1,fontFamily:'Kia-sig' }}>
                          We’ll be happy to answer all your queries.
                        </Typography>
                        <Typography variant="subtitle1" fontWeight="bold" fontFamily="Kia-sig">
                          1800 108 5000
                        </Typography>
                        <Button 
                          variant="outlined" 
                          color="inherit" 
                          onClick={() => {
                            const isMobile = /Mobi|Android/i.test(navigator.userAgent);
                            if (isMobile) {
                              window.location.href = 'tel:18001085000';
                            } else {
                              alert('Please call 1800 108 5000 for more information.');
                            }
                          }}
                        >
                          Call Us
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>


              {/* Faqs */}
              <Faqs/>

        </Box>
    </Box>

  );
};

export default Ev9;
