import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import "./Home.css";
import Video from "../../Video/Syrosbanner.mp4";
import Carasole from "./carasole";
import fullimg from "../../Image/DJI_0909.jpg";
import fullimg2 from "../../Image/MapCentralKia.jpg";
import fullimg3 from "../../Image/Central Kia Website Resources/responsive-d.jpg";
import fullimg4 from "../../Image/Central Kia Website Resources/adaptive_d.jpg";
import fullimg5 from "../../Image/Central Kia Website Resources/connected-desktop.jpg";
import Cardreview from "../Container/cardsCon";
import Footer from "../../Footer/Component/Footer";
import "../../../App.css";
const Home = (props) => {
  const [openTestDriveModal, setOpenTestDriveModal] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);

  const handleTestDriveOpen = () => setOpenTestDriveModal(true);
  const handleTestDriveClose = () => setOpenTestDriveModal(false);

  const handleServiceOpen = () => setOpenServiceModal(true);
  const handleServiceClose = () => setOpenServiceModal(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    modelType: "",
    carRegistrationNumber: "",
    preferredServiceCenterLocation: "",
    date: "",
  });
  const [formData1, setFormData1] = useState({
    name: "",
    email: "",
    mobile: "",
    modelType: "",
    date: "",
    address: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmitService = (e) => {
    e.preventDefault();

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Mobile validation (10 digits, numeric only)
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData.mobile)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }

    // If validations pass, proceed with form submission
    console.log("Form submitted:", formData);

    props.AddServiceForm(
      formData.name,
      formData.email,
      formData.mobile,
      formData.modelType,
      formData.carRegistrationNumber,
      formData.preferredServiceCenterLocation,
      formData.date
    );

    // Reset formData to empty strings
    setFormData({
      name: "",
      email: "",
      mobile: "",
      modelType: "",
      carRegistrationNumber: "",
      preferredServiceCenterLocation: "",
      date: "",
    });
    handleServiceClose();
  };
  const handleSubmitTestDrive = (e) => {
    e.preventDefault();
    console.log("Form Submitted: ", formData1);
    // Perform validation or submit action here
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData1.email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Mobile validation (10 digits, numeric only)
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData1.mobile)) {
      alert("Please enter a valid 10-digit mobile number.");
      return;
    }

    props.BookTestDrive(
      formData1.name,
      formData1.email,
      formData1.mobile,
      formData1.modelType,
      formData1.date,
      formData1.address
    );
    setFormData1({
      name: "",
      email: "",
      mobile: "",
      modelType: "",
      date: "",
      address: "",
    });
    handleTestDriveClose();
  };

  const items = [
    { text: "Book a test drive", action: "modal" },
    { text: "Get Quotations", link: "/pricing" },
    { text: "Book Service Appointment", action: "modal-service" },
    { text: "Download Brochure", link: "/brochure" },
  ];
  useEffect(() => {
    props.viewAddesByStatus();
    // window.scrollTo({ top: 0, behavior: "smooth" });

    console.log(props.client.addesByStatus, " props.client.addesByStatus");
  }, []);
  const handleWhatsAppClick = () => {
    window.open("https://wa.me/9237301338", "_blank"); // Opens WhatsApp link
  };
  return (
    <Grid container>
      {/* Banner Video */}
      <Grid item xs={12}>
        <video
          playsInline
          autoPlay
          loop
          muted
          preload="auto"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <source src={Video} type="video/mp4" />
        </video>
      </Grid>

      {/* 4 card section */}
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} sm={6} md={5}>
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              {props.client.addesByStatus?.mediaType === "image" ? (
                <img
                  src={props.client.addesByStatus?.image}
                  alt="Car"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                  }}
                />
              ) : (
                <video
                  src={props.client.addesByStatus?.image}
                  autoPlay
                  muted
                  loop
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={7}>
            <Grid container>
              {items.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  key={index}
                  id={`right${index + 1}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    margin: 0,
                    padding: "8px",
                    letterSpacing: { xs: "0.1px", md: "0.2px" },
                    wordSpacing: { xs: "0.3px", md: "2px" },
                    cursor: "pointer",
                    fontFamily: "KIAbodyLatinbasic_Regular",
                    border: "1px solid transparent",
                    "&:hover": {
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    },
                  }}
                  onClick={() => {
                    if (item.action === "modal") {
                      handleTestDriveOpen();
                    } else if (item.action === "modal-service") {
                      handleServiceOpen();
                    }
                  }}
                >
                  {item.link ? (
                    <Link
                      to={item.link}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "7px",
                        textDecoration: "none",
                        justifyContent: "center",
                        fontSize: { xs: "12px", sm: "14px", md: "16px" },
                        color: "inherit",
                        width: "100%",
                        height: "100%",
                        fontFamily: "KIAbodyLatinbasic_Regular",
                      }}
                    >
                      <i
                        className={`fa-solid fa-${
                          index === 0
                            ? "car"
                            : index === 1
                            ? "quote-left"
                            : index === 2
                            ? "gears"
                            : "book"
                        }`}
                        style={{ marginRight: "8px" }}
                      ></i>
                      {item.text}
                    </Link>
                  ) : (
                    <>
                      <i
                        className={`fa-solid fa-${
                          index === 0
                            ? "car"
                            : index === 1
                            ? "quote-left"
                            : index === 2
                            ? "gears"
                            : "book"
                        }`}
                        style={{ marginRight: "8px" }}
                      ></i>
                      <p
                        style={{
                          margin: 0,
                          fontFamily: "KIAbodyLatinbasic_Regular",
                          // fontSize: { xs: "12px", sm: "14px", md: "18px" },
                        }}
                      >
                        {item.text}
                      </p>
                    </>
                  )}
                </Grid>
              ))}
            </Grid>

            {/* Service */}
            <Dialog
              open={openServiceModal}
              onClose={handleServiceClose}
              maxWidth="md"
              fullWidth
              BackdropProps={{
                style: {
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  backdropFilter: "blur(5px)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "90%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#D62E31",
                      textAlign: "center",
                      fontSize: { md: "40px", xs: "20px", sm: "35px" },
                      fontFamily: "KIAbodyLatinbasic, Arial, sans-serif",
                      fontWeight: "bolder",
                    }}
                  >
                    Service Appointment Form
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50px",
                    color: "black",
                    cursor: "pointer",
                  }}
                  onClick={handleServiceClose}
                >
                  <CloseIcon />
                </Box>
              </Box>

              <DialogContent>
                <form onSubmit={handleSubmitService}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        className
                        sx={{
                          fontSize: "12px",
                          textAlign: "center",
                          fontFamily: "KIAbodyLatinbasic_Regular",
                        }}
                      >
                        Please fill in the mandatory details for Service
                        Appointment.
                      </Typography>
                    </Grid>

                    {/* Name and Email */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="dense"
                        label="Name"
                        name="name"
                        fullWidth
                        required
                        value={formData.name}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="dense"
                        label="Email"
                        name="email"
                        type="email"
                        fullWidth
                        required
                        value={formData.email}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                        }}
                      />
                    </Grid>

                    {/* Mobile and Model */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        margin="dense"
                        label="Mobile"
                        name="mobile"
                        type="number"
                        fullWidth
                        required
                        value={formData.mobile}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth margin="dense" required>
                        <InputLabel
                          sx={{ "& .MuiInputLabel-asterisk": { color: "red" } }}
                        >
                          Model
                        </InputLabel>
                        <Select
                          name="modelType"
                          value={formData.modelType}
                          onChange={handleChange}
                        >
                          <MenuItem value="seltos">Seltos</MenuItem>
                          <MenuItem value="sonet">Sonet</MenuItem>
                          <MenuItem value="carens">Carens</MenuItem>
                          <MenuItem value="ev6">EV6</MenuItem>
                          <MenuItem value="carnival">Carnival</MenuItem>
                          <MenuItem value="ev9">EV9</MenuItem>
                          <MenuItem value="syros">Syros</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Car Registration and Service Center */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        required
                        label="Car Registration Number"
                        type="text"
                        variant="standard"
                        placeholder="e.g: OD39ZZ5697"
                        name="carRegistrationNumber"
                        value={formData.carRegistrationNumber}
                        onChange={handleChange}
                        InputLabelProps={{
                          sx: {
                            "& .MuiInputLabel-asterisk": { color: "#d32f2f" },
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth required variant="standard">
                        <InputLabel
                          sx={{
                            "& .MuiInputLabel-asterisk": { color: "#d32f2f" },
                          }}
                        >
                          Preferred Service Center Location
                        </InputLabel>
                        <Select
                          name="preferredServiceCenterLocation"
                          value={formData.preferredServiceCenterLocation}
                          onChange={handleChange}
                        >
                          <MenuItem value="bbsr">Bhubaneswar</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Preferred Date */}
                    <Grid item xs={12}>
                      <TextField
                        margin="dense"
                        label="Preferred Date for Service"
                        name="date"
                        type="datetime-local"
                        fullWidth
                        value={formData.date}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                          sx: { "& .MuiInputLabel-asterisk": { color: "red" } },
                        }}
                        required
                      />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          backgroundColor: "#D23132",
                          fontWeight: "bolder",
                          "&:hover": {
                            backgroundColor: "#D23132",
                          },
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </DialogContent>
            </Dialog>

            {/* Test Drive */}
            <Dialog
              open={openTestDriveModal}
              onClose={handleTestDriveClose}
              maxWidth="md"
              fullWidth
              BackdropProps={{
                style: {
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  backdropFilter: "blur(5px)",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    width: "800px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#D62E31",
                      textAlign: "center",
                      fontSize: { md: "40px", xs: "20px", sm: "35px" },
                      fontFamily: "KIAbodyLatinbasic, Arial, sans-serif",
                      fontWeight: "bolder",
                    }}
                  >
                    Book a Test Drive
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50px",
                  }}
                  onClick={handleTestDriveClose}
                >
                  <CloseIcon />
                </Box>
              </Box>
              <DialogContent>
                <form onSubmit={handleSubmitTestDrive}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "12px",
                          textAlign: "center",
                          fontWeight: "700",
                          fontFamily: "KIAbodyLatinbasic_Regular",
                        }}
                      >
                        Please fill in the mandatory details for booking a Test
                        Drive.
                      </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Name"
                          name="name"
                          fullWidth
                          required
                          value={formData1.name}
                          onChange={handleChange1}
                          InputLabelProps={{
                            sx: {
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Email"
                          name="email"
                          type="email"
                          fullWidth
                          required
                          value={formData1.email}
                          onChange={handleChange1}
                          InputLabelProps={{
                            sx: {
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Mobile"
                          name="mobile"
                          type="number"
                          fullWidth
                          required
                          value={formData1.mobile}
                          onChange={handleChange1}
                          InputLabelProps={{
                            sx: {
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin="dense" required>
                          <InputLabel
                            sx={{
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            }}
                          >
                            Model Type
                          </InputLabel>
                          <Select
                            name="modelType"
                            value={formData1.modelType}
                            onChange={handleChange1}
                          >
                            <MenuItem value="seltos">Seltos</MenuItem>
                            <MenuItem value="sonet">Sonet</MenuItem>
                            <MenuItem value="carens">Carens</MenuItem>
                            <MenuItem value="ev6">EV6</MenuItem>
                            <MenuItem value="carnival">Carnival</MenuItem>
                            <MenuItem value="ev9">EV9</MenuItem>
                            <MenuItem value="syros">Syros</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Test Drive Date & Time"
                          name="date"
                          type="datetime-local"
                          fullWidth
                          value={formData1.date}
                          onChange={handleChange1}
                          InputLabelProps={{
                            shrink: true,
                            sx: {
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            },
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Address"
                          name="address"
                          type="text"
                          fullWidth
                          value={formData1.address}
                          onChange={handleChange1}
                          InputLabelProps={{
                            shrink: true,
                            sx: {
                              "& .MuiInputLabel-asterisk": { color: "red" },
                            },
                          }}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          backgroundColor: "#D23132",
                          fontWeight: "bolder",
                          "&:hover": {
                            backgroundColor: "#D23132",
                          },
                        }}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Grid>
                </form>
              </DialogContent>
            </Dialog>
          </Grid>
        </Grid>
      </Grid>

      {/* Carrousel section */}
      <Carasole />

      {/* Content1 Section */}
      <Box
        sx={{
          padding: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            paddingBottom: "20px",
            fontSize: { xs: "17px", md: "25px", sm: "20px" },
            fontFamily: "KIAbodyLatinbasic_Bold",
            fontWeight: 800,
          }}
        >
          Central Kia: The Largest Kia Dealership Network in Odisha | Seltos,
          Sonet, Carens, EV6 | Sales, Service & Spare
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "12px", md: "15px", sm: "13px" },
            paddingBottom: "30px",
            fontFamily: "KIAbodyLatinbasic_Regular",
          }}
        >
          Central Kia, the largest Kia dealership network in Odisha. With
          showrooms in Bhubaneswar, Cuttack, Angul, Sambalpur, Jajpur, and
          Berhampur, we offer a wide range of Kia vehicles to meet your needs.
          Our state-of-the-art service centers in Bhubaneswar, Sambalpur, and
          Berhampur ensure your Kia stays in top condition. Visit Central Kia
          for the best deals on Kia cars, expert maintenance, and outstanding
          customer service. Discover the perfect Kia for you at Central Kia,
          your trusted Kia dealer in Odisha.
        </Typography>
      </Box>

      {/* Image */}
      <Grid
        container
        sx={{
          width: "100%",
          height: { md: "100vh", xs: "40vh", sm: "60vh" },
          backgroundImage: `url(${fullimg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          margin: 0,
          padding: 0,
          overflowX: "hidden",
        }}
      ></Grid>

      {/* Content2 */}
      <Box
        sx={{
          padding: 4,
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            paddingBottom: "20px",
            fontSize: { xs: "17px", md: "25px", sm: "20px" },
            fontFamily: "KIAbodyLatinbasic_Bold",
            fontWeight: 800,
            textAlign: "center",
          }}
        >
          Wide Presence Across Odisha
        </Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "20px" }}
        >
          <Grid item xs={12} sm={10} md={8}>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "12px", sm: "13px", md: "15px" },
                paddingBottom: "20px",
                fontFamily: "KIAbodyLatinbasic_Regular",
              }}
            >
              We make it easy for you to experience the best of Kia, with
              showrooms located in:
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                paddingLeft: "20px",
                fontFamily: "KIAbodyLatinbasic_Regular",
              }}
            >
              {[
                "Angul",
                "Berhampur",
                "Bhubaneswar",
                "Cuttack",
                "Jajpur",
                "Jharsuguda",
                "Sambalpur",
              ].map((city, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "list-item",
                    fontSize: { xs: "12px", sm: "13px", md: "15px" },
                  }}
                >
                  {city}
                </ListItem>
              ))}
            </List>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "12px", sm: "13px", md: "15px" },
                paddingTop: "10px",
                fontFamily: "KIAbodyLatinbasic_Regular",
                textAlign: "center",
              }}
            >
              No matter where you are in Odisha, a Central Kia showroom is never
              far away, ensuring you have access to the latest Kia models and
              expert guidance.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/* Image2 */}
      <Grid
        container
        sx={{
          width: "100%",
          height: { md: "120vh", xs: "40vh", sm: "60vh" },
          backgroundImage: `url(${fullimg2})`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          margin: 0,
          padding: 0,
          overflowX: "hidden",
        }}
      ></Grid>

      {/* Content3  */}
      <Box
        sx={{
          padding: 4,
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            paddingBottom: "20px",
            fontSize: { xs: "17px", md: "25px", sm: "20px" },
            fontFamily: "KIAbodyLatinbasic_Bold",
            fontWeight: 800,
          }}
        >
          State-of-the-Art Service Centers
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "12px", md: "15px", sm: "13px" },
            paddingBottom: "20px",
            fontFamily: "KIAbodyLatinbasic_Regular",
          }}
        >
          At Central Kia, your Kia's helath and performance are our top
          priorities. Our service centers in Bhubaneswar, Sambalpur, and
          Berhampur are equipped with advanced tools and staffed by
          Kia-certified technicians to keep your vehicle in peak condition. From
          routine maintainance to major repairs, we've got you covered with
          quality service and genuine Kia parts.
        </Typography>
      </Box>

      {/* Image3 */}
      <Grid
        container
        sx={{
          width: "100%",
          height: { md: "100vh", xs: "40vh", sm: "60vh" },
          backgroundImage: `url(${fullimg3})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          margin: 0,
          padding: 0,
          overflowX: "hidden",
        }}
      ></Grid>

      {/* Content4  */}
      <Box
        sx={{
          padding: 4,
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            fontWeight: 800,
            fontSize: { xs: "17px", md: "25px", sm: "20px" },
            fontFamily: "KIAbodyLatinbasic_Bold",
          }}
        >
          Why Choose Central Kia?
        </Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ padding: "20px" }}
        >
          <Grid item xs={12} sm={10} md={8}>
            <List
              sx={{
                listStyleType: "decimal",
                fontFamily: "KIAbodyLatinbasic_Regular",
              }}
            >
              {[
                "Customer-Centric Approach: We prioritize your needs and provide personalized assistance throughout your journey.",
                "Exclusive Offers: Enjoy unbeatable deals and financing options on Kia cars.",
                "Comprehensive Services: From test drives and vehicle selection to after-sales maintainance, we cover it all.",
                "Modern Facilities: Experience premium showrooms and state-of-the-art service centers.",
              ].map((city, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "list-item",
                    fontSize: { xs: "12px", sm: "13px", md: "15px" },
                  }}
                >
                  {city}
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>

      {/* Image4 */}
      <Grid
        container
        sx={{
          width: "100%",
          height: { sm: "60vh", xs: "40vh", md: "100vh" },
          backgroundImage: `url(${fullimg4})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          margin: 0,
          padding: 0,
          overflowX: "hidden",
        }}
      ></Grid>

      {/* Content5  */}
      <Box
        sx={{
          padding: 4,
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          sx={{
            fontWeight: 800,
            paddingBottom: "20px",
            fontSize: { xs: "17px", md: "25px", sm: "20px" },
            fontFamily: "KIAbodyLatinbasic_Bold",
          }}
        >
          Experience the Central Kia Difference
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "12px", md: "15px", sm: "13px" },
            paddingBottom: "20px",
            fontFamily: "KIAbodyLatinbasic_Regular",
          }}
        >
          Join thousands of satisfied customers who trust Central Kia for their
          automative needs. Whether you're looking for the perfect Kia car,
          expert maintainanc, or unparalleled customer service, we're here to
          exceed your expectations.
        </Typography>
      </Box>

      {/* Image5 */}
      <Grid
        container
        sx={{
          width: "100%",
          height: { sm: "60vh", xs: "40vh", md: "100vh" },
          backgroundImage: `url(${fullimg5})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          margin: 0,
          padding: 0,
          overflowX: "hidden",
        }}
      ></Grid>

      {/* Customer review */}
      <Cardreview />

      {/* Floating WhatsApp Button */}
      <IconButton
        onClick={handleWhatsAppClick}
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          backgroundColor: "#25D366",
          color: "#fff",
          width: 56,
          height: 56,
          boxShadow: 3,
          "&:hover": {
            backgroundColor: "#1DA955",
          },
        }}
      >
        <WhatsAppIcon sx={{ fontSize: 30 }} />
      </IconButton>
    </Grid>
  );
};

export default Home;
