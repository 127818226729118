import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  IconButton,
  TextField,
  Button,
  useMediaQuery,
  Fade,
  Divider,
  Typography,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useNavigate } from "react-router-dom";
import Support from "../../Image/customer-service.png";

const HeaderActions = (props) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const modalRef = useRef(null);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  // Close modal on outside click
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpen(false);
    }
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const handlePhoneClick = () => {
    if (window.innerWidth <= 768) {
      window.location.href = "tel:09237386213";
    } else {
      alert("Please use your phone to make the call.");
    }
  };

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/09237301338", "_blank");
  };

  const handleSupportClick = () => {
    navigate("/support");
  };

  const handleEmailClick = () => {
    setOpen(!open);
  };

  const handleSendEmail = () => {
    // Simulate email send and close modal
    alert("Email sent successfully!");
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        justifyContent: "flex-end",
        p: "10px",
        backgroundColor: "#333333",
        color: "white",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        height: "40px",
      }}
    >
      <Box sx={{display:"flex",alignItems:"center",gap:"2px"}}>
      <IconButton onClick={handlePhoneClick}>
        <PhoneIcon sx={{ color: "white" }} />  
      </IconButton>
      <Typography  sx={{color:"white", fontWeight:"bold"}}>09237386213</Typography>
      </Box>

      <Divider orientation="vertical" flexItem sx={{ bgcolor: "gray" }} />

      <IconButton onClick={handleSupportClick}>
        <img src={Support} alt="Support" style={{ height: 20, width: 20 }} />
      </IconButton>

      <Divider orientation="vertical" flexItem sx={{ bgcolor: "gray" }} />

      <IconButton onClick={handleEmailClick}>
        <MailOutlineIcon sx={{ color: "white" }} />
      </IconButton>

      <Divider orientation="vertical" flexItem sx={{ bgcolor: "gray" }} />

      <IconButton onClick={handleWhatsAppClick}>
        <WhatsAppIcon sx={{ color: "green" }} />
      </IconButton>

      {open && (
        <Fade in={open}>
          <Box
            ref={modalRef}
            sx={{
              position: "absolute",
              top: isSmallScreen ? "20%" : 87,
              right: isSmallScreen ? "10%" : 20,
              width: isSmallScreen ? "80%" : 300,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: 2,
              p: 2,
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
              color: "white",
              paddingTop: "20px",
              marginTop: "40px",
              zIndex: 1000,
              height: isSmallScreen ? "auto" : 400,
              backdropFilter: "blur(10px)",
            }}
          >
            <TextField
              fullWidth
              onChange={handleEmailChange}
              placeholder="Type your email ID"
              variant="outlined"
              size="small"
              sx={{
                fontSize: "18px",
                backgroundColor: "white",
                borderRadius: 2,
                marginBottom: 4,
                "& input": {
                  fontSize: "14px",
                },
              }}
            />
            <TextField
              fullWidth
              placeholder="Type your message..."
              multiline
              rows={isSmallScreen ? 4 : 9}
              variant="outlined"
              size="small"
              onChange={handleMessageChange}
              sx={{
                fontSize: "18px",
                backgroundColor: "white",
                borderRadius: 2,
                marginBottom: 4,
                "& textarea": {
                  fontSize: "14px",
                },
              }}
            />
            <Button
              onClick={() => {
                props.AddContactForm(email, message);
                setMessage("");
                setEmail("");
              }}
              variant="contained"
              fullWidth
              sx={{
                fontSize: "18px",
                backgroundColor: "#333",
                color: "white",
                "&:hover": {
                  backgroundColor: "#555",
                },
              }}
            >
              Send
            </Button>
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default HeaderActions;
