import React, { Component } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  TextField,
  Button,
  Typography,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Visibility } from "@mui/icons-material";
import { Delete, Edit } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import _ from "lodash";
import "./addes.css";
function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: false,
      add: false,
      edit: false,
      reject: false,
      selectedEmployee: null,
      searchInput: "",
      page: 0,
      rowsPerPage: 10,
      image: null,
      candidates_id: 0,
      selectedData: null,
      addes_id: "",
      startDate: "",
      endDate: "",
      selectedStatus: "",
      media: null,
      mediaType: "",
    };
    this.debouncedSearch = _.debounce(this.handleSearch, 300);
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.props.viewAlladdes(
        this.props.login.token,
        this.props.login.user_id,
        this.state.searchInput,
        newPage,
        this.state.rowsPerPage,
        this.state.startDate,
        this.state.endDate,
        this.state.selectedStatus
      );
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      },
      () => {
        this.props.viewAlladdes(
          this.props.login.token,
          this.props.login.user_id,
          this.state.searchInput,
          0,
          this.state.rowsPerPage,
          this.state.startDate,
          this.state.endDate,
          this.state.selectedStatus
        );
      }
    );
  };

  toggleStatus = async (item) => {
    const newStatus = item.status === "Active" ? "Inactive" : "Active";
    this.props.updateStatus(
      this.props.login.token,
      this.props.login.user_id,
      item._id,
      newStatus,
      this.state.page,
      this.state.rowsPerPage
    );
  };

  handleSearch = () => {
    this.props.viewAlladdes(
      this.props.login.token,
      this.props.login.user_id,
      this.state.searchInput,
      this.state.page,
      this.state.rowsPerPage,
      this.state.startDate,
      this.state.endDate,
      this.state.selectedStatus
    );
  };

  handleSearchChange = (event) => {
    const searchInput = event.target.value;
    this.setState({ searchInput }, () => {
      this.debouncedSearch();
    });
  };

  handleDialogOpen = (employee) => {
    this.setState({ selectedEmployee: employee, view: true });
  };

  handleDialogClose = () => {
    this.setState({ view: false, selectedEmployee: null });
  };

  componentDidMount() {
    this.props.viewAlladdes(
      this.props.login.token,
      this.props.login.user_id,
      this.state.searchInput,
      this.state.page,
      this.state.rowsPerPage,
      this.state.startDate,
      this.state.endDate,
      this.state.selectedStatus
    );
  }

  handleStartDateChange = (event) => {
    this.setState({ startDate: event.target.value });
    this.props.viewAlladdes(
      this.props.login.token,
      this.props.login.user_id,
      this.state.searchInput,
      this.state.page,
      this.state.rowsPerPage,
      event.target.value,
      this.state.endDate,
      this.state.selectedStatus
    );
    console.log("Selected Date:", event.target.value);
  };

  handleEndDateChange = (event) => {
    this.setState({ endDate: event.target.value });
    this.props.viewAlladdes(
      this.props.login.token,
      this.props.login.user_id,
      this.state.searchInput,
      this.state.page,
      this.state.rowsPerPage,
      this.state.startDate,
      event.target.value,
      this.state.selectedStatus
    );

    console.log("Selected Time:", event.target.value);
  };

  handleStatusChange = (event) => {
    this.setState({ selectedStatus: event.target.value });
    this.props.viewAlladdes(
      this.props.login.token,
      this.props.login.user_id,
      this.state.searchInput,
      this.state.page,
      this.state.rowsPerPage,
      this.state.startDate,
      this.state.endDate,
      event.target.value
    );

    console.log("Selected Status:", event.target.value);
  };
  handleMediaChange = (e, type) => {
    const file = e.target.files[0];
    this.setState({
      media: file,
      mediaType: type,
    });
  };

  render() {
    const {
      searchInput,
      selectedEmployee,
      view,
      add,
      edit,
      page,
      rowsPerPage,
    } = this.state;

    return (
      <Grid
        container
        spacing={2}
        style={{ alignItems: "center", marginTop: "50px" }}
        className="KIAbodyLatinbasic_Regular"
      >
        {/* Header Section: Create Advertisement Button */}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({ add: true })}
            sx={{fontFamily:"KIAbodyLatinbasic_Regular",}}
          >
            Create Advertisement
          </Button>
        </Grid>

        {/* Filter and Pagination Section */}
        <Grid
          item
          xs={12}
          style={{ marginBottom: "20px", alignItems: "center" }}
        >
          <Grid container spacing={2} style={{ alignItems: "center" }}>
            {/* Start Date Filter */}
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                label="Start Date"
                type="date"
                onChange={this.handleStartDateChange}
                InputLabelProps={{ shrink: true }}
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
              />
            </Grid>

            {/* End Date Filter */}
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                label="End Date"
                type="date"
                onChange={this.handleEndDateChange}
                InputLabelProps={{ shrink: true }}
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
              />
            </Grid>

            {/* Status Filter */}
            <Grid item xs={12} sm={4} md={3}>
              <Select
                label="Filter by Status"
                onChange={this.handleStatusChange}
                style={{ width: "100%" }}
                variant="outlined"
                size="small"
                defaultValue=""
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </Grid>

            {/* Pagination Section */}
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <TablePagination
                component="div"
                count={this.props.client?.all_addeslength || 0}
                page={page}
                onPageChange={this.handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                style={{ overflow: "hidden" }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Advertisement Table */}
        <Grid item xs={12}>
          <TableContainer
            component={Paper}
            style={{
              borderRadius: "8px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow style={{ backgroundColor: "#f5f5f5" }}>
                  {["Sl No", "Image", "Date", "Time", "Status", "Action"].map(
                    (header, index) => (
                      <TableCell key={index} align="center">
                        <Typography
                          variant="subtitle2"
                          style={{ fontWeight: "bold" }}
                        >
                          {header}
                        </Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(this.props.client.all_addes) &&
                  this.props.client.all_addes?.map((item, index) => {
                    const createdAt = new Date(item.createdAt);
                    const date = createdAt.toLocaleDateString(); // Formats date as MM/DD/YYYY
                    const time = createdAt.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    }); 

                    return (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#fafafa" : "#ffffff",
                          transition: "background-color 0.3s",
                          "&:hover": { backgroundColor: "#f0f0f0" },
                        }}
                      >
                        <TableCell align="center">
                          <Typography variant="body2">{index + 1}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          {item.mediaType === "image" ? (
                            <img
                              src={item.image}
                              alt="Advertisement"
                              style={{
                                width: "80px",
                                height: "80px",
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                              }}
                            />
                          ) : (
                            <video
                              src={item.image}
                              controls
                              style={{
                                width: "80px",
                                height: "80px",
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                              }}
                            />
                          )}
                        </TableCell>

                        <TableCell align="center">
                          <Typography variant="body2">{date}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">{time}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              item.status === "Active"
                                ? "Set Inactive"
                                : "Set Active"
                            }
                          >
                            <IconButton onClick={() => this.toggleStatus(item)}>
                              <div className="toggle-switch">
                                <input
                                  type="checkbox"
                                  checked={item.status === "Active"}
                                  onChange={() => this.toggleStatus(item)}
                                />
                                <span className="slider round"></span>
                              </div>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="View Image">
                            <IconButton
                              onClick={() => this.handleDialogOpen(item)}
                            >
                              <Visibility color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() =>
                                this.setState({
                                  selectedData: item,
                                  edit: true,
                                })
                              }
                            >
                              <Edit color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() =>
                                this.setState({
                                  addes_id: item._id,
                                  reject: true,
                                })
                              }
                            >
                              <Delete color="error" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        {selectedEmployee && (
          <Dialog
            open={view}
            onClose={this.handleDialogClose}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Image Details</DialogTitle>
            <DialogContent dividers>
              <Grid container justifyContent="center">
                {selectedEmployee.mediaType === "image" ? (
                  <img
                    src={selectedEmployee.image}
                    alt="Employee"
                    style={{ width: "100%", height: "auto" }}
                  />
                ) : (
                  <video
                    src={selectedEmployee.image}
                    controls
                    style={{ width: "100%", height: "auto" }}
                  />
                )}
              </Grid>

            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleDialogClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <Dialog
          open={add}
          onClose={() => this.setState({ add: false, image: null })}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Upload an Image</DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-image"
                  type="file"
                  onChange={(e) => this.handleMediaChange(e, "image")}
                />
                <label htmlFor="upload-image">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="video/*"
                  style={{ display: "none" }}
                  id="upload-video"
                  type="file"
                  onChange={(e) => this.handleMediaChange(e, "video")}
                />
                <label htmlFor="upload-video">
                  <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                    fullWidth
                  >
                    Upload Video
                  </Button>
                </label>
              </Grid>

              {this.state.media && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Selected {this.state.mediaType}: {this.state.media.name}
                  </Typography>
                  {this.state.mediaType === "image" ? (
                    <img
                      src={URL.createObjectURL(this.state.media)}
                      alt="Selected Preview"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginTop: "10px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        maxHeight: "300px",
                      }}
                    />
                  ) : (
                    <video
                      controls
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginTop: "10px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        maxHeight: "300px",
                      }}
                    >
                      <source src={URL.createObjectURL(this.state.media)} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.props.uploadImaes(
                  this.props.login.token,
                  this.props.login.user_id,
                  this.state.media,
                  this.state.mediaType,
                  this.state.page,
                  this.state.rowsPerPage
                );
                this.setState({ add: false, media: null, mediaType: "" });
              }}
              color="primary"
              variant="contained"
            >
              Add
            </Button>
            <Button
              onClick={() => this.setState({ add: false, image: null })}
              color="secondary"
              variant="outlined"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.edit}
          onClose={() => this.setState({ edit: false, image: null })}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Upload an Image</DialogTitle>
          <DialogContent>
            <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-image"
                  type="file"
                  onChange={(e) => this.handleMediaChange(e, "image")}
                />
                <label htmlFor="upload-image">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    fullWidth
                  >
                    Upload Image
                  </Button>
                </label>
              </Grid>

              <Grid item xs={12}>
                <input
                  accept="video/*"
                  style={{ display: "none" }}
                  id="upload-video"
                  type="file"
                  onChange={(e) => this.handleMediaChange(e, "video")}
                />
                <label htmlFor="upload-video">
                  <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                    fullWidth
                  >
                    Upload Video
                  </Button>
                </label>
              </Grid>
 {this.state.media && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Selected {this.state.mediaType}: {this.state.media.name}
                  </Typography>
                  {this.state.mediaType === "image" ? (
                    <img
                      src={URL.createObjectURL(this.state.media)}
                      alt="Selected Preview"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginTop: "10px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        maxHeight: "300px",
                      }}
                    />
                  ) : (
                    <video
                      controls
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        marginTop: "10px",
                        borderRadius: "8px",
                        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                        maxHeight: "300px",
                      }}
                    >
                      <source src={URL.createObjectURL(this.state.media)} />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </Grid>
              )}
              {/* Image Preview Section */}
              
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                if (this.state.media) {
                  this.props.uploadupdatesImages(
                    this.props.login.token,
                    this.props.login.user_id,
                    this.state.selectedData._id,
                    this.state.media,
                    this.state.mediaType,
                    this.state.page,
                    this.state.rowsPerPage
                  );
                }
                this.setState({ edit: false, media: null,mediaType:"" });
              }}
              color="primary"
              variant="contained"
            >
              Save Changes
            </Button>
            <Button
              onClick={() => this.setState({ edit: false, image: null })}
              color="secondary"
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.reject}
          onClose={() => {
            this.setState({ reject: false, addes_id: "" });
          }}
        >
          <DialogTitle>Confirm Reject</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to Delete the Advertisement?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                this.props.deleteAddes(
                  this.props.login.token,
                  this.props.login.user_id,
                  this.state.addes_id,
                  this.state.page,
                  this.state.rowsPerPage
                );
                this.setState({ reject: false, addes_id: "" });
              }}
              color="primary"
            >
              Yes
            </Button>
            <Button
              onClick={() => this.setState({ reject: false })}
              color="secondary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withParams(Employee);
