export const menuItems = [
  {
    title: "Home",
    path:'/'
  },
  {
    title: "Cars",
    submenu: [
      {
        title: "Seltos",
        path: "/seltos",
      },
      {
        title: "Sonet",
        path: "/sonet",
      },
      {
        title: "Carens",
        path: "/carens",
      },
      {
        title: "EV6",
        path: "/ev6",
      },
      {
        title: "Carnival",
        path: "/carnival",
      },
      {
        title: "EV9",
        path: "/ev9",
      },
      {
        title: "Syros",
        path: "/syros",
      },
    ],
  },
  {
    title: "Preowned Cars",
    path:"/preowned"
  },

  {
    title: "Service",
    path:"/service"
  },
  {
    title: "Contact Us",
    path:"/contact"
  },
  {
    title: "About Us",
    path:"/about"
  },
  {
    title:"Enquiry",
    path:"/enquiry"

  }
];
