import React from 'react';
import { 
  Box,
} from '@mui/material';
import Showinterest from '../../../show interest/Components/showinterest';
import Video from '../../../Video/Syrosbanner.mp4';
import Wsp from '../../../contact/Component/Floatingwsp';


const Syros = () => {

  return (
    <Box sx={{overflowX: 'hidden'}}>
        <Box sx={{ position: 'relative', height: '100vh', overflowX: 'hidden',marginTop:'40px' }}>
            <video
              playsInline
              autoPlay
              loop
              muted
              preload="auto"
              style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
              }}
            >
            <source src={Video} type="video/mp4" />
            </video>     

        </Box>
        <Wsp/>
        {/* show interest */}
        <Showinterest someValue="Syros"/>        
    </Box>

  );
};

export default Syros;
