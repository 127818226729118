import React from 'react';
import { Box, Typography, Grid, Divider, IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import BuildIcon from '@mui/icons-material/Build';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useMediaQuery } from '@mui/material'; // for responsive design
// import Forminfo from '../Container/supportFormCon'
const Support = () => {
  const isMobile = useMediaQuery('(max-width:600px)'); // Adjust breakpoint if necessary
  const isTablet = useMediaQuery('(min-width:600px) and (max-width:960px)');

  const handleCallClick = () => {
    if (window.innerWidth <= 768) {
      window.location.href = 'tel:09237386213';
    } else {
      alert('Please use your phone to make the call.');
    }
  };


  const handleWhatsAppClick = () => {
    window.open('https://wa.me/09237301338', '_blank'); // Opens WhatsApp link
  };

    const handleEmailClick = () => {
      window.location.href = 'mailto:customercare@centeralkia.in'; // Opens the default email client
    };

  return (
    <Box sx={{ padding: '20px', marginTop: '20px' }}>
     

      {/* Title */}
      <Typography
        variant="h4"
        className="KIAbodyLatinbasic_Regular"
        sx={{
          fontWeight: 'bold',
          marginBottom: 1,
          color: '#3A3A3A',
          fontFamily: 'KIAbodyLatinbasic_Regular',
          fontWeight: 800,
          marginTop: '20px',
          marginLeft: "40px"
        }}
      >
        Support
      </Typography>
      <Typography
        variant="body1"
        className="KIAbodyLatinbasic_Regular"
        sx={{
          marginBottom: 4,
          color: '#666',
          marginLeft: "40px",
          fontFamily:"KIAbodyLatinbasic_Regular"
        }}
      >
        Get your queries answered using any of our official communication channels.
      </Typography>

      <Grid container spacing={6} alignItems="center" width="90%">
        {/* Call Us Section */}
        <Grid item xs={12} >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "40px",pointer:"cursor" }}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: 'block' }, height: '90px', margin: "20px", width: '10px', backgroundColor: "#CCCCCC" }}
            />
            <PhoneIcon
              sx={{
                fontSize: 80,
                backgroundColor: '#f5f5f5',
                borderRadius: '50%',
                padding: 1,
                cursor: 'pointer',
                marginBottom: 1,
                '&:hover': {
                  backgroundColor: "#3A3A3A",
                  color: "white"
                }
              }}
             
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography variant="body1"  className="KIAbodyLatinbasic_Regular" onClick={handleCallClick} sx={{ fontFamily:"KIAbodyLatinbasic_Regular", marginBottom: 0.5 }}>
                <b className="KIAbodyLatinbasic_Regular" style={{ color: '#3A3A3A', cursor: 'pointer',fontFamily:"KIAbodyLatinbasic_Regular" }}>Call Us:</b> 09237386213
              </Typography>
              <Typography variant="body1" className="KIAbodyLatinbasic_Regular"  onClick={handleWhatsAppClick} sx={{ fontFamily:"KIAbodyLatinbasic_Regular" }} >
                <b className="KIAbodyLatinbasic_Regular" style={{ color: "#3A3A3A",  cursor: 'pointer',fontFamily:"KIAbodyLatinbasic_Regular" }}>WhatsApp:</b> 09237301338
              </Typography>
            </Box>
          </Box>
        </Grid>

         {/* Roadside number Section */}
        {/* <Grid item xs={12} >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "40px",pointer:"cursor" }}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: 'block' }, height: '90px', margin: "20px", width: '10px', backgroundColor: "#CCCCCC" }}
            />
            <BuildIcon
              sx={{
                fontSize: 80,
                backgroundColor: '#f5f5f5',
                borderRadius: '50%',
                padding: 1,
                cursor: 'pointer',
                marginBottom: 1,
                '&:hover': {
                  backgroundColor: "#3A3A3A",
                  color: "white"
                }
              }}
             
            />
              <Typography variant="body1"  onClick={handleCallClick} >
                <b  className = "LeagueSpatan-Bold" style={{ color: '#3A3A3A', cursor: 'pointer',fontFamily:"LeagueSpartan-Bold" }}>Kia Roadside Assistance Number: </b>  <span  className = "LeagueSpatan-Regular"style={{ color: '#3A3A3A', cursor: 'pointer'
                  ,fontFamily:"LeagueSpatan-Regular" }}>18004192622</span>
              </Typography>
            </Box>
        </Grid> */}

          {/* Email Section */}
          <Grid item xs={12} >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "40px",pointer:"cursor" }}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: 'block' }, height: '90px', margin: "20px", width: '10px', backgroundColor: "#CCCCCC" }}
            />
            <EmailIcon
              sx={{
                fontSize: 80,
                backgroundColor: '#f5f5f5',
                borderRadius: '50%',
                padding: 1,
                cursor: 'pointer',
                marginBottom: 1,
                '&:hover': {
                  backgroundColor: "#3A3A3A",
                  color: "white"
                }
              }}
             
            />
              <Typography variant="body1" className="KIAbodyLatinbasic_Regular" onClick={handleEmailClick} sx={{ fontFamily:"KIAbodyLatinbasic_Regular"}}>
              customercare@centralkia.in
              </Typography>
            </Box>
        </Grid>

         {/* Address Section */}
         <Grid item xs={12} >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginLeft: "40px",pointer:"cursor" }}>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ display: { xs: 'block' }, height: '90px', margin: "20px", width: '10px', backgroundColor: "#CCCCCC" }}
            />
            <PlaceIcon
              sx={{
                fontSize: 80,
                backgroundColor: '#f5f5f5',
                borderRadius: '50%',
                padding: 1,
                cursor: 'pointer',
                marginBottom: 1,
                '&:hover': {
                  backgroundColor: "#3A3A3A",
                  color: "white"
                }
              }}
             
            />
              
              <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Typography variant="body1" className="KIAbodyLatinbasic_Regular" sx={{ cursor:"pointer", fontFamily:"KIAbodyLatinbasic_Regular"}}>
              <b  style={{ color: '#3A3A3A', cursor: 'pointer',fontFamily:"KIAbodyLatinbasic_Regular" }}> Head Office Address :</b>PLOT NO-655 - 656, NH-16, NAKHARA, BALIANTA, BHUBANESWAR, Khordha, Odisha, 752101
              </Typography>
              <Typography variant="body1" className="KIAbodyLatinbasic_Regular" sx={{ fontFamily:"KIAbodyLatinbasic_Regular" }} >
                <b className="KIAbodyLatinbasic_Regular" style={{ color: "#3A3A3A",  cursor: 'pointer',fontFamily:"KIAbodyLatinbasic_Regular" }}>Showroom Address:</b> Plot No. 644/2148, NH-16, Pahal, Bhubaneswar, Odisha 752101
              </Typography>
            </Box>
            </Box>
        </Grid>

      </Grid>

            {/* <Forminfo/> */}
      {/* Floating WhatsApp Button */}
      <IconButton
        onClick={handleWhatsAppClick}
        sx={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          backgroundColor: '#25D366',
          color: '#fff',
          width: 56,
          height: 56,
          boxShadow: 3,
          '&:hover': {
            backgroundColor: '#1DA955',
          },
        }}
      >
        <WhatsAppIcon sx={{ fontSize: 30 }} />
      </IconButton>
    </Box>
  );
};

export default Support;
