import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useParams } from "react-router-dom";
export default function Viewcars(props) {
  const [image, setImage] = useState([]);
  const { id } = useParams();
  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    setImage(selectedImages);
  };
  useEffect(() => {
    props.viewOldCarById(props.login.token, props.login.user_id, id);
    props.viewOverviewById(props.login.token, props.login.user_id, id);
    props.viewSpecificationById(props.login.token, props.login.user_id, id);
    props.viewFeturesById(props.login.token, props.login.user_id, id);

    console.log("20pxccccccccccccc", props.client.viewoldCarById);
    console.log("20pxcxxxxx", props.client.viewOverviewById);

    console.log("20pxccssssssss", props.client.viewFetureById);
    console.log("aaaaaaaaaa", props.client.viewSpecificationById);
  }, [props.login.token, props.login.user_id, id]);
  return (
    <>
      {/* addinfo */}
      <Box sx={{ padding: "20px" }} className="KIAbodyLatinbasic_Regular">
        <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{ marginTop: "70px" }}
        >
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      color: "#cf2230",
                      fontFamily:"KIAbodyLatinbasic_Regular",
                      fontSize: { md: "22px", sm: "20px", xs: "17px" },
                    }}
                  >
                    BASIC INFORMATION
                  </Typography>
                </Box>

                {/* Display selected images */}
                <Box
                  sx={{
                    marginBottom: 2,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  {props.client.viewoldCarById.image.map((image, index) => (
                    <Box key={index} sx={{ margin: 1 }}>
                      <img
                        src={image} 
                        alt={`Selected ${index + 1}`}
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  ))}
                </Box>

                {/* Information Display */}
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="150px">
                        Brand Name:
                      </Typography>
                      <Typography>
                        {props.client.viewoldCarById.brand}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="150px">
                        Car Name:
                      </Typography>
                      <Typography>
                        {props.client.viewoldCarById.carName}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="150px">
                        Fuel Type:
                      </Typography>
                      <Typography>
                        {props.client.viewoldCarById.fuelType}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="150px">
                        Car Type:
                      </Typography>
                      <Typography>
                        {props.client.viewoldCarById.carType}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="150px">
                        Price:
                      </Typography>
                      <Typography>
                        {props.client.viewoldCarById.price}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="150px">
                        Discounted Price:
                      </Typography>
                      <Typography>
                        {props.client.viewoldCarById.discountedPrice}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="150px">
                        Kilometer:
                      </Typography>
                      <Typography>
                        {props.client.viewoldCarById.kilometer}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* overview */}
        <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{ marginTop: "71px", marginBottom: "20px" }}
        >
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      color: "#cf2230",
                      fontFamily:"KIAbodyLatinbasic_Regular",
                      fontSize: { md: "22px", sm: "20px", xs: "17px" },
                    }}
                  >
                    OVERVIEW
                  </Typography>
                </Box>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", gap: "15px" }}>
                        <Typography fontWeight="bold" width="150px">
                          Registration Year :
                        </Typography>
                        <Typography id="registration-year-label">
                          {props.client.viewOverviewById.registerationYear}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", gap: "15px" }}>
                        <Typography sx={{ fontWeight: "bold", width: "150px" }}>
                          Manufacturing Year :
                        </Typography>
                        <Typography>
                          {props.client.viewOverviewById.manufacturingYear}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", gap: "15px" }}>
                        <Typography fontWeight="bold" width="150px">
                          No. of Owners:
                        </Typography>
                        <Typography>
                          {props.client.viewOverviewById.owners}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", gap: "15px" }}>
                        <Typography fontWeight="bold" width="150px">
                          Transmission :
                        </Typography>
                        <Typography>
                          {props.client.viewOverviewById.transmission}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", gap: "15px" }}>
                        <Typography fontWeight="bold" width="150px">
                          Color :
                        </Typography>
                        <Typography>
                          {props.client.viewOverviewById.color}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ display: "flex", gap: "15px" }}>
                        <Typography fontWeight="bold" width="150px">
                          Insurance :
                        </Typography>
                        <Typography>
                          {props.client.viewOverviewById.insurance}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "15px",
                          marginBottom: "15px",
                        }}
                      >
                        <Typography fontWeight="bold" width="150px">
                          Registration Type :{" "}
                        </Typography>
                        <Typography>
                          {props.client.viewOverviewById.registerationType}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          marginBottom: 2,
                          fontWeight: "bold",
                          color: "#cf2230",
                          fontFamily:"KIAbodyLatinbasic_Regular",
                          fontSize: { md: "22px", sm: "20px", xs: "17px" },
                        }}
                      >
                        SELLER'S COMMENT
                      </Typography>
                    </Box>
                    <Grid item xs={12}>
                      <Box sx={{}}>
                        <Typography>
                          {props.client.viewOverviewById.description}
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* specification */}
        <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{ marginTop: "71px", marginBottom: "20px" }}
        >
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      color: "#cf2230",
                      fontFamily:"KIAbodyLatinbasic_Regular",
                      fontSize: { md: "22px", sm: "20px", xs: "17px" },
                    }}
                  >
                    SPECIFICATIONS
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "10px",
                    fontWeight: "800",
                  }}
                >
                  <u>Engine & Transmission</u>
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Engine(in cc) :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.engine} cc
                      </Typography>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Max Power (bhp@rpm) :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.maxPower} rpm
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Max Torque (Nm@rpm) :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.maxTorque} rpm
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Mileage (ARAI) :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.mileage} kmpl
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      <Typography fontWeight="bold" width="180px">
                        Drive Train :{" "}
                      </Typography>
                      <Typography>
                        {props.client.viewSpecificationById.driveTrain}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12} sm={4}>
                                <TextField label="Turbocharger / Supercharger" variant="outlined" fullWidth value={turbocharger} onChange={(e) => setTurboCharger(e.target.value)} />
                                </Grid> */}
                </Grid>

                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "10px",
                    fontWeight: "800",
                  }}
                >
                  <u>Dimensions & Weight</u>
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Length :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.length}mm
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Width :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.width} mm
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Height :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.height} mm
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Wheelbase :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.wheelbase} mm
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Ground Clearance :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.groundClearance} mm
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      <Typography fontWeight="bold" width="180px">
                        Kerb Weight :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.kerbWeight} kg
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "10px",
                    fontWeight: "800",
                  }}
                >
                  <u>Capacity</u>
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Doors :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.doors}Doors
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Seating Capacity :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {
                          props.client.viewSpecificationById.seatingCapacity
                        }{" "}
                        Person
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        No of Rows :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.rows} Rows
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Bootspace :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.bootspace} litres
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      <Typography fontWeight="bold" width="180px">
                        Fuel Tank Capacity :{" "}
                      </Typography>
                      <Typography>
                        {props.client.viewSpecificationById.fuelTankCapacity}{" "}
                        litres
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    marginBottom: "10px",
                    fontWeight: "800",
                  }}
                >
                  <u>Suspensions, Brakes, Steering & Tyres</u>
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Front Suspension :{" "}
                      </Typography>
                      <Typography>
                        {props.client.viewSpecificationById.frontSuspension}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Rear Suspension :{" "}
                      </Typography>
                      <Typography>
                        {props.client.viewSpecificationById.rearSuspension}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Front Brake Type :{" "}
                      </Typography>
                      <Typography>
                        {props.client.viewSpecificationById.frontbrakeType}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Rear Brake Type :{" "}
                      </Typography>
                      <Typography>
                        {props.client.viewSpecificationById.rearBrakeType}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Min Turning Radius :{" "}
                      </Typography>
                      <Typography>
                        {props.client.viewSpecificationById.minTurningRadius}{" "}
                        metres
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Steering Type :{" "}
                      </Typography>
                      <Typography>
                        {props.client.viewSpecificationById.steeringType}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Wheels :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.wheels}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Spare Wheel :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.spareWheel}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="180px">
                        Front Tyres :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.frontTyres}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      <Typography fontWeight="bold" width="180px">
                        Rear Tyres :{" "}
                      </Typography>
                      <Typography>
                        {" "}
                        {props.client.viewSpecificationById.rearTyres}{" "}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/* feature */}
        <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{ marginTop: "71px", marginBottom: "20px" }}
        >
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      color: "#cf2230",
                      fontFamily:"KIAbodyLatinbasic_Regular",
                      fontSize: { md: "22px", sm: "20px", xs: "17px" },
                    }}
                  >
                    FEATURES
                  </Typography>
                </Box>
                <form>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    <u>Safety</u>
                  </Typography>
                  <Grid item xs={12} sm={12}>
                    <Box sx={{ display: "flex", gap: "15px" }}>
                      <Typography fontWeight="bold" width="80px">
                        Airbags :
                      </Typography>
                      <Typography>
                        {props.client.viewFetureById.airbag}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="tyrePressure"
                          color="primary"
                          checked={props.client.viewFetureById.tyrePresssure} // assuming `tyrePressure` is boolean
                        />
                      }
                      label="Tyre Pressure Monitoring System (TPMS)"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="childSeatAnchorPoints"
                          color="primary"
                          checked={
                            props.client.viewFetureById.childSeatAnchorPoints
                          } // assuming `childSeatAnchorPoints` is boolean
                        />
                      }
                      label="Child Seat Anchor Points"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="seatBeltWarning"
                          color="primary"
                          checked={props.client.viewFetureById.seatBeltWarning}
                        />
                      }
                      label="Seat Belt Warning"
                    />
                  </Grid>
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Braking & Traction
                  </Typography>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Anti-Lock Braking System (ABS)"
                            color="primary"
                            checked={props.client.viewFetureById.abs}
                          />
                        }
                        label="Anti-Lock Braking System (ABS)"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Electronic Brake-force Distribution (EBD)"
                            color="primary"
                            checked={props.client.viewFetureById.ebd}
                          />
                        }
                        label="Electronic Brake-force Distribution (EBD)"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Brake Assist (BA)"
                            color="primary"
                            checked={props.client.viewFetureById.ba}
                          />
                        }
                        label="Brake Assist (BA)"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Electronic Stability Program (ESP)"
                            color="primary"
                            checked={props.client.viewFetureById.esp}
                          />
                        }
                        label="Electronic Stability Program (ESP)"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Four-Wheel-Drive"
                            color="primary"
                            checked={props.client.viewFetureById.fourWheel}
                          />
                        }
                        label="Four-Wheel-Drive"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Hill Hold Control"
                            color="primary"
                            checked={
                              props.client.viewFetureById.hillHoldControl
                            }
                          />
                        }
                        label="Hill Hold Control"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Traction Control System (TC/TCS)"
                            color="primary"
                            checked={props.client.viewFetureById.tcs}
                          />
                        }
                        label="Traction Control System (TC/TCS)"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Hill Descent Control"
                            color="primary"
                            checked={
                              props.client.viewFetureById.hillDescentControl
                            }
                          />
                        }
                        label="Hill Descent Control"
                      />
                    </Grid>
                    
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Locks & Security
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="engineImmobiliser"
                          color="primary"
                          checked={
                            props.client.viewFetureById.engineImmobiliser
                          }
                        />
                      }
                      label="Engine Immobiliser"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="centralLocking"
                          color="primary"
                          checked={props.client.viewFetureById.centeralLocking}
                        />
                      }
                      label="Central Locking"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="speedSensingDoorLock"
                          color="primary"
                          checked={
                            props.client.viewFetureById.speedSensingDoorLock
                          }
                        />
                      }
                      label="Speed Sensing Door Lock"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="childSafetyLock"
                          color="primary"
                          checked={props.client.viewFetureById.childSafetyLock}
                        />
                      }
                      label="Child Safety Lock"
                    />
                  </Grid>

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Comfort & Convenience
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="ac"
                          color="primary"
                          checked={props.client.viewFetureById.ac}
                        />
                      }
                      label="Air Conditioner"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="frontac"
                          color="primary"
                          checked={props.client.viewFetureById.frontac}
                        />
                      }
                      label="Front AC"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="rearac"
                          color="primary"
                          checked={props.client.viewFetureById.rearac}
                        />
                      }
                      label="Rear AC"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="heater"
                          color="primary"
                          checked={props.client.viewFetureById.heater}
                        />
                      }
                      label="Heater"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Vanity Mirrors on Sun Visors"
                          color="primary"
                          checked={props.client.viewFetureById.vanityMirror}
                        />
                      }
                      label="Vanity Mirrors on Sun Visors"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Anti-glare Mirrors"
                          color="primary"
                          checked={props.client.viewFetureById.antiglareMirror}
                        />
                      }
                      label="Anti-glare Mirrors"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="parkingAssist"
                          color="primary"
                          checked={props.client.viewFetureById.parkingAssist}
                        />
                      }
                      label="Parking Assist"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Parking Sensors"
                          color="primary"
                          checked={props.client.viewFetureById.parkingSensor}
                        />
                      }
                      label="Parking Sensors"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Cruise Control"
                          color="primary"
                          checked={props.client.viewFetureById.cruisecontrol}
                        />
                      }
                      label="Cruise Control"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Headlight and Ignition On Reminder"
                          color="primary"
                          checked={
                            props.client.viewFetureById.headlightIgnition
                          }
                        />
                      }
                      label="Headlight and Ignition On Reminder"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Keyless Start/ Button Start"
                          color="primary"
                          checked={props.client.viewFetureById.keylessstart}
                        />
                      }
                      label="Keyless Start/ Button Start"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="SteeringHeightAdjustment"
                          color="primary"
                          checked={
                            props.client.viewFetureById.steeringAdjustment
                          }
                        />
                      }
                      label="Steering Height Adjustment"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="12V Power Outlets"
                          color="primary"
                          checked={props.client.viewFetureById.power}
                        />
                      }
                      label="12V Power Outlets"
                    />
                  </Grid>
                 
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Mobile App Features
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox name="applecarplay" color="primary" />}
                      label="Apple Carplay"
                      checked={props.client.viewFetureById.applecarplay}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox name="androidauto" color="primary" />}
                      label="Android Auto"
                      checked={props.client.viewFetureById.androidauto}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                                <TextField
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                value={des4}
                                onChange={(e) => setDes4(e.target.value)}
                                />
                            </Grid> */}

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Seats & Upholstery
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Seat Upholstery"
                          color="primary"
                          checked={props.client.viewFetureById.seatUpholestry}
                        />
                      }
                      label="Seat Upholstery"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="driverSide"
                          color="primary"
                          checked={props.client.viewFetureById.drivesideAdjust}
                        />
                      }
                      label="Driver Side Seat Adjustment"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Leather-wrapped Steering Wheel"
                          color="primary"
                          checked={props.client.viewFetureById.leatherWrapped}
                        />
                      }
                      label="Leather-wrapped Steering Wheel"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Driver Armrest"
                          color="primary"
                          checked={props.client.viewFetureById.driverArmrest}
                        />
                      }
                      label="Driver Armrest"
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="Rear Passenger Seat Type"
                                    color="primary"
                                    value={rearPassengerSeatType}
                                    onChange={(e) => setRearPassengerSeatType(e.target.value)}
                                    />
                                }
                                label="Rear Passenger Seat Type"
                                />
                            </Grid> */}
                  {/* <Grid item xs={12}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="Interiors"
                                    color="primary"
                                    value={interiors}
                                    onChange={(e) => setInterior(e.target.value)}
                                    />
                                }
                                label="Interiors"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="Interior Colours"
                                    color="primary"
                                    value={interiorsColor}
                                    onChange={(e) => setInteriorColor(e.target.value)}
                                    />
                                }
                                label="Interior Colours"
                                />
                            </Grid> */}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Folding Rear Seat"
                          color="primary"
                          checked={props.client.viewFetureById.foldingRearSeat}
                        />
                      }
                      label="Folding Rear Seat"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="splitRearSeat"
                          color="primary"
                          checked={props.client.viewFetureById.splitRearSeat}
                        />
                      }
                      label="Split Rear Seat"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Split Third Row Seat"
                          color="primary"
                          checked={
                            props.client.viewFetureById.splitThirdRowSeat
                          }
                        />
                      }
                      label="Split Third Row Seat"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Front Seatback Pockets"
                          color="primary"
                          checked={
                            props.client.viewFetureById.frontSeatbackPockets
                          }
                        />
                      }
                      label="Front Seatback Pockets"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Headrests"
                          color="primary"
                          checked={props.client.viewFetureById.headrests}
                        />
                      }
                      label="Headrests"
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                                <TextField
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                value={des5}
                                onChange={(e) => setDes5(e.target.value)}
                                />
                            </Grid> */}

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Storage
                  </Typography>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="cupholders"
                          color="primary"
                          checked={props.client.viewFetureById.cupholders}
                        />
                      }
                      label="Cup Holders"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Driver Armrest Storage"
                          color="primary"
                          checked={
                            props.client.viewFetureById.driverArmrestStorage
                          }
                        />
                      }
                      label="Driver Armrest Storage"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Cooled Glovebox"
                          color="primary"
                          checked={props.client.viewFetureById.cooledGloveBox}
                        />
                      }
                      label="Cooled Glovebox"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Sunglass Holder"
                          color="primary"
                          checked={props.client.viewFetureById.sunGlassHolder}
                        />
                      }
                      label="Sunglass Holder"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Third Row Cup Holders"
                          color="primary"
                          checked={
                            props.client.viewFetureById.thirdRowCupHolder
                          }
                        />
                      }
                      label="Third Row Cup Holders"
                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                                <TextField
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                value={des6}
                                onChange={(e) => setDes6(e.target.value)}
                                />
                            </Grid> */}

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Doors, Windows, Mirrors & Wipers
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="ORVM Colour"
                          color="primary"
                          checked={props.client.viewFetureById.orvm}
                        />
                      }
                      label="Body Color ORVM"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Power Windows"
                          color="primary"
                          checked={props.client.viewFetureById.powerWindow}
                        />
                      }
                      label="Power Windows"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="One Touch Down"
                          color="primary"
                          checked={props.client.viewFetureById.oneTouchDown}
                        />
                      }
                      label="One Touch Down"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="One Touch Up"
                          color="primary"
                          checked={props.client.viewFetureById.oneTouchUp}
                        />
                      }
                      label="One Touch Up"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Adjustable ORVMs"
                          color="primary"
                          checked={props.client.viewFetureById.adjustableOrvm}
                        />
                      }
                      label="Electric Adjustable ORVMs"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Body color Exterior Door Handles"
                          color="primary"
                          checked={
                            props.client.viewFetureById.exteriorDoorHandle
                          }
                        />
                      }
                      label="Body Color Exterior Door Handles"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="chromeExterior Door Handles"
                          color="primary"
                          checked={
                            props.client.viewFetureById.chromeexteriorDoorHandle
                          }
                        />
                      }
                      label="Chrome Exterior Door Handles"
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="Interior Door Handles"
                                    color="primary"
                                    value={interiorDoorHandle}
                                    onChange={(e) => setInteriorDoorHandle(e.target.value)}
                                    />
                                }
                                label="Interior Door Handles"
                                />
                            </Grid> */}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Door Pockets"
                          color="primary"
                          checked={props.client.viewFetureById.doorPockets}
                        />
                      }
                      label="Door Pockets"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Bootlid Opener"
                          color="primary"
                          checked={props.client.viewFetureById.bootlidOpener}
                        />
                      }
                      label="Bootlid Opener"
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                                <TextField
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                value={des7}
                                onChange={(e) => setDes7(e.target.value)}
                                />
                            </Grid> */}

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Exterior
                  </Typography>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Sunroof / Moonroof"
                          color="primary"
                          checked={props.client.viewFetureById.sunroof}
                        />
                      }
                      label="Sunroof / Moonroof"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Roof-mounted Antenna"
                          color="primary"
                          checked={
                            props.client.viewFetureById.roofMountedAntenna
                          }
                        />
                      }
                      label="Roof-mounted Antenna"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Body-coloured Bumpers"
                          color="primary"
                          checked={
                            props.client.viewFetureById.bodyColouredBumpers
                          }
                        />
                      }
                      label="Body-coloured Bumpers"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Chrome Finish Exhaust Pipe"
                          color="primary"
                          checked={props.client.viewFetureById.chromeFinish}
                        />
                      }
                      label="Chrome Finish Exhaust Pipe"
                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                                <TextField
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                value={des8}
                                onChange={(e) => setDes8(e.target.value)}
                                />
                            </Grid> */}

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Lighting
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="projectorHeadlights"
                          color="primary"
                          checked={
                            props.client.viewFetureById.projectorheadlight
                          }
                        />
                      }
                      label="Projector Headlights"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Automatic Headlamps"
                          color="primary"
                          checked={props.client.viewFetureById.autoheadlamps}
                        />
                      }
                      label="Automatic Headlamps"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Follow Me Home Headlamps"
                          color="primary"
                          checked={props.client.viewFetureById.homeheadlamps}
                        />
                      }
                      label="Follow Me Home Headlamps"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="drl"
                          color="primary"
                          checked={props.client.viewFetureById.drl}
                        />
                      }
                      label="DRL"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Taillights"
                          color="primary"
                          checked={props.client.viewFetureById.tailights}
                        />
                      }
                      label="LED Taillights"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="cabinLamps"
                          color="primary"
                          checked={props.client.viewFetureById.cabinLamps}
                        />
                      }
                      label="Cabin Lamps"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Light on Vanity Mirrors"
                          color="primary"
                          checked={
                            props.client.viewFetureById.lightonVanityMirror
                          }
                        />
                      }
                      label="Light on Vanity Mirrors"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Rear Reading Lamp"
                          color="primary"
                          checked={props.client.viewFetureById.rearreadinglamp}
                        />
                      }
                      label="Rear Reading Lamp"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Glovebox Lamp"
                          color="primary"
                          checked={props.client.viewFetureById.gloveBoxlamp}
                        />
                      }
                      label="Glovebox Lamp"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Headlight Height Adjuster"
                          color="primary"
                          checked={
                            props.client.viewFetureById.headlightHeightAdjuster
                          }
                        />
                      }
                      label="Headlight Height Adjuster"
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                                <TextField
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                value={des9}
                                onChange={(e) => setDes9(e.target.value)}
                                />
                            </Grid> */}

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Instrumentation
                  </Typography>
                  {/* <Grid item xs={12}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="Instantaneous Consumption"
                                    color="primary"
                                    value={instantaneousConsumption}
                                    onChange={(e) =>
                                        setInstantaneousConsumption(e.target.value)
                                    }
                                    />
                                }
                                label="Instantaneous Consumption"
                                />
                            </Grid> */}
                  {/* <Grid item xs={12}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="Instrument Cluster"
                                    color="primary"
                                    value={instrumentCluster}
                                    onChange={(e) => setInstrumentCluster(e.target.value)}
                                    />
                                }
                                label="Instrument Cluster"
                                />
                            </Grid> */}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Trip Meter"
                          color="primary"
                          checked={props.client.viewFetureById.tripMeter}
                        />
                      }
                      label="Trip Meter"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Average Fuel Consumption"
                          color="primary"
                          checked={
                            props.client.viewFetureById.averageFuelConsumption
                          }
                        />
                      }
                      label="Average Fuel Consumption"
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="Average Speed"
                                    color="primary"
                                    value={averageSpeed}
                                    onChange={(e) => setAverageSpeed(e.target.value)}
                                    />
                                }
                                label="Average Speed"
                                />
                            </Grid> */}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Distance to Empty"
                          color="primary"
                          checked={props.client.viewFetureById.distancetoEmpty}
                        />
                      }
                      label="Distance to Empty"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="clock"
                          color="primary"
                          checked={props.client.viewFetureById.clock}
                        />
                      }
                      label="Clock"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Low Fuel Level Warning"
                          color="primary"
                          checked={props.client.viewFetureById.lowFuelWarning}
                        />
                      }
                      label="Low Fuel Level Warning"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Door Ajar Warning"
                          color="primary"
                          checked={props.client.viewFetureById.doorAjarWarning}
                        />
                      }
                      label="Door Ajar Warning"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Adjustable Cluster Brightness"
                          color="primary"
                          checked={
                            props.client.viewFetureById.adjustableCluster
                          }
                        />
                      }
                      label="Adjustable Cluster Brightness"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Gear Indicator"
                          color="primary"
                          checked={props.client.viewFetureById.gearIndicator}
                        />
                      }
                      label="Gear Indicator"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Shift Indicator"
                          color="primary"
                          checked={props.client.viewFetureById.shiftIndicator}
                        />
                      }
                      label="Shift Indicator"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Tachometer"
                          color="primary"
                          checked={props.client.viewFetureById.tachometer}
                        />
                      }
                      label="Tachometer"
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                                <TextField
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                value={des10}
                                onChange={(e) => setdes10(e.target.value)}
                                />
                            </Grid> */}

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Entertainment, Information & Communication
                  </Typography>

                  {/* <Grid item xs={12}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="Display"
                                    color="primary"
                                    value={display}
                                    onChange={(e) => setDisplay(e.target.value)}
                                    />
                                }
                                label="Display"
                                />
                            </Grid> */}
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Integrated (in-dash) Music System"
                          color="primary"
                          checked={props.client.viewFetureById.musicSystem}
                        />
                      }
                      label="Integrated (in-dash) Music System"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Speakers"
                          color="primary"
                          checked={props.client.viewFetureById.speaker}
                        />
                      }
                      label="Speakers"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Steering-mounted controls"
                          color="primary"
                          checked={
                            props.client.viewFetureById.steeringmountedctrls
                          }
                        />
                      }
                      label="Steering-mounted controls"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Voice Command"
                          color="primary"
                          checked={props.client.viewFetureById.voiceCommand}
                        />
                      }
                      label="Voice Command"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="GPS Navigation System"
                          color="primary"
                          checked={props.client.viewFetureById.gps}
                        />
                      }
                      label="GPS Navigation System"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Bluetooth Compatibility"
                          color="primary"
                          checked={props.client.viewFetureById.bluetooth}
                        />
                      }
                      label="Bluetooth Compatibility"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="AUX Compatibility"
                          color="primary"
                          checked={props.client.viewFetureById.aux}
                        />
                      }
                      label="AUX Compatibility"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="AM/FM Radio"
                          color="primary"
                          checked={props.client.viewFetureById.radio}
                        />
                      }
                      label="AM/FM Radio"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="USB Compatibility"
                          color="primary"
                          checked={props.client.viewFetureById.usb}
                        />
                      }
                      label="USB Compatibility"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Head Unit Size"
                          color="primary"
                          checked={props.client.viewFetureById.headUnitSize}
                        />
                      }
                      label="Head Unit Size"
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="iPod Compatibility"
                                    color="primary"
                                    value={iPod}
                                    onChange={(e) => setIpod(e.target.value)}
                                    />
                                }
                                label="iPod Compatibility"
                                />
                            </Grid> */}

                  {/* <Grid item xs={12}>
                                <TextField
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                value={des11}
                                onChange={(e) => setDes11(e.target.value)}
                                />
                            </Grid> */}

                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      margin: "10px",
                      fontWeight: "800",
                    }}
                  >
                    Manufacturer Warranty
                  </Typography>

                  {/* <Grid item xs={12}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="warranty"
                                    color="primary"
                                    value={warrrantyyear}
                                    onChange={(e) => setWarranty(e.target.value)}
                                    />
                                }
                                label="Warranty (2 Years)"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                control={
                                    <Checkbox
                                    name="warrantyKm"
                                    color="primary"
                                    value={warrrantykm}
                                    onChange={(e) => setWarrantykm(e.target.value)}
                                    />
                                }
                                label="Warranty (40000 Kilometres)"
                                />
                            </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      variant="outlined"
                      multiline
                      rows={4}
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      value={props.client.viewFetureById.des12}
                    />
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
