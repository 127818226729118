import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import image1 from '../../Image/kia carousel/kiacarouselseltos.jpg';
import image2 from '../../Image/kia carousel/kiacarouselsonet.jpg';
import image3 from '../../Image/kia carousel/kiacarouselcarens.jpg';
import image4 from '../../Image/kia carousel/kiacarouselev6.jpg';
import image5 from '../../Image/kia carousel/kiacarouselcarnival.jpg';
import image6 from '../../Image/kia carousel/kiacarouselev9.jpg';

// Array of image data with corresponding descriptions and links
const images = [
  { src: image1, link: '/seltos' },
  { src: image2, link: '/sonet' },
  { src: image3, link: '/carens' },
  { src: image4, link: '/ev6' },
  { src: image5, link: '/carnival' },
  { src: image6, link: '/ev9' },
];

const ImageCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: { xs: '300px', sm: '400px', md: '600px' },
        textAlign: 'center',
      }}
    >
      <Grid container sx={{ height: '100%' }}>
        <Grid
          item
          xs={12}
          sx={{
            backgroundImage: `url(${images[currentIndex].src})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          {/* Make the image clickable */}
          <Link
            to={images[currentIndex].link}
            style={{ position: 'absolute', width: '100%', height: '100%' }}
          ></Link>
        </Grid>

        {/* Left Arrow */}
        <IconButton
          onClick={handlePrev}
          sx={{
            position: 'absolute',
            left: { xs: '10px', md: '40px', lg: '70px' },
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          }}
        >
          <ArrowBackIos />
        </IconButton>

        {/* Right Arrow */}
        <IconButton
          onClick={handleNext}
          sx={{
            position: 'absolute',
            right: { xs: '10px', md: '40px', lg: '70px' },
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            color: '#fff',
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
          }}
        >
          <ArrowForwardIos />
        </IconButton>
      </Grid>
    </Box>
  );
};

export default ImageCarousel;
