import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

export default function Overview(props) {
  const [registerationYear, setRegisterationYear] = useState("");
  const [manufacturingYear, setManufacturingYear] = useState("");
  const [owners, setOwners] = useState("");
  const [transmission, setTransmission] = useState("");
  const [color, setColor] = useState("");
  const [insurance, setInsurance] = useState("");
  const [registerationType, setregisterationType] = useState("");
  const [description, setDescription] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(props.client.oldCarId);

    if (props.client.oldCarId !== "") {
      props.addOverview(
        props.login.token,
        props.login.user_id,
        props.client.oldCarId,
        registerationYear,
        manufacturingYear,
        owners,
        transmission,
        color,
        insurance,
        registerationType,
        description
      );
      navigate("/specification"); 
    } else {
      console.log("Old Car Id is missing");
    }
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      sx={{ marginTop: "71px", marginBottom: "20px" }}
      className="KIAbodyLatinbasic_Regular"
    >
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 2,
                  fontWeight: "bold",
                  color: "#cf2230",
                  fontFamily:"KIAbodyLatinbasic_Regular",
                  fontSize: { md: "22px", sm: "20px", xs: "17px" },
                }}
              >
                OVERVIEW
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel id="registration-year-label">
                      Registration Year
                    </InputLabel>
                    <Select
                      labelId="registration-year-label"
                      label="Registration Year"
                      name="registeryear"
                      required
                      value={registerationYear}
                      onChange={(e) => setRegisterationYear(e.target.value)}
                    >
                      {[...Array(15)].map((_, i) => {
                        const year = 2024 - i;
                        return (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel>Manufacturing Year</InputLabel>
                    <Select
                      label="Manufacturing Year"
                      name="manufactureyear"
                      required
                      value={manufacturingYear}
                      onChange={(e) => setManufacturingYear(e.target.value)}
                    >
                      {[...Array(15)].map((_, i) => {
                        const year = 2023 - i;
                        return (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel>No Of Owners</InputLabel>
                    <Select
                      label="No Of Owners"
                      name="owner"
                      required
                      value={owners}
                      onChange={(e) => setOwners(e.target.value)}
                    >
                      <MenuItem value="First Owner">First Owner</MenuItem>
                      <MenuItem value="Second Owner">Second Owner</MenuItem>
                      <MenuItem value="Third or More">Third or More</MenuItem>
                      <MenuItem value="Unregistered Car">
                        Unregistered Car
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel>Transmission</InputLabel>
                    <Select
                      label="Transmission"
                      name="transmission"
                      required
                      value={transmission}
                      onChange={(e) => setTransmission(e.target.value)}
                    >
                      <MenuItem value="Automatic">Automatic</MenuItem>
                      <MenuItem value="Manual">Manual</MenuItem>
                      <MenuItem value="Clutchless Manual">
                        Clutchless Manual
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Color"
                    variant="outlined"
                    fullWidth
                    name="color"
                    value={color}
                    onChange={(e) => setColor(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Insurance"
                    variant="outlined"
                    fullWidth
                    name="insurance"
                    value={insurance}
                    onChange={(e) => setInsurance(e.target.value)}
                    required
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl variant="outlined" fullWidth required>
                    <InputLabel>Registration Type </InputLabel>
                    <Select
                      label="Registration Type"
                      name="registerationType"
                      required
                      value={registerationType}
                      onChange={(e) => setregisterationType(e.target.value)}
                    >
                      <MenuItem value="private">Private</MenuItem>
                      <MenuItem value="commercial">Commercial</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 2,
                      fontWeight: "bold",
                      color: "#cf2230",
                      fontFamily:"KIAbodyLatinbasic_Regular",
                      fontSize: { md: "22px", sm: "20px", xs: "17px" },
                    }}
                  >
                    SELLER'S COMMENT
                  </Typography>
                </Box>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    fullWidth
                    sx={{ marginBottom: 2 }}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </Grid>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "center", gap: "22px" }}
              >
                
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#E03012",
                    fontWeight: "bold",
                    width: "150px",
                    "&:hover": {
                      backgroundColor: "#E03012",
                    },
                  }}
                >
                  Next
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
