import React, { useState, useEffect } from 'react';
import './Carousel.css';
import { Box, Typography,Button,Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import gravity1 from '../../../Image/Carens/gravity1.jpg';
import gravity2 from '../../../Image/Carens/gravity2.jpg';
import gravity3 from '../../../Image/Carens/gravity3.jpg';
import gravity4 from '../../../Image/Carens/gravity4.jpg';
import gravity5 from '../../../Image/Carens/gravity5.jpg';
import x1 from '../../../Image/Carens/x1.jpg';
import x2 from '../../../Image/Carens/x2.jpg';
import x3 from '../../../Image/Carens/x3.jpg';
import x4 from '../../../Image/Carens/x4.jpg';
import x5 from '../../../Image/Carens/x5.jpg';
import ShowInterest from '../../../show interest/Container/showinterestCon';
import ext1 from '../../../Image/Carens/ext1.jpg'
import ext2 from '../../../Image/Carens/ext2.jpg'
import ext3 from '../../../Image/Carens/ext3.jpg'
import ext4 from '../../../Image/Carens/ext4.jpg';
import ext5 from '../../../Image/Carens/ext5.jpg';
import ext6 from '../../../Image/Carens/ext6.jpg';
import int1 from '../../../Image/Carens/int1.jpg';
import int2 from '../../../Image/Carens/int2.jpg';
import int3 from '../../../Image/Carens/int3.jpg';
import int4 from '../../../Image/Carens/int4.jpg';
import int5 from '../../../Image/Carens/int5.jpg';
import int6 from '../../../Image/Carens/int6.jpg';
import per1 from '../../../Image/Carens/per1.jpg';
import per2 from '../../../Image/Carens/per2.jpg';
import per3 from '../../../Image/Carens/per3.jpg';
import con1 from '../../../Video/Carens/con1.mp4';
import con2 from '../../../Image/Carens/con2.jpg';
import con3 from '../../../Image/Carens/con3.jpg';
import con4 from '../../../Image/Carens/con4.jpg';
import con5 from '../../../Image/Carens/con5.jpg';
import car1 from '../../../Image/Carens/car1.jpg';
import car2 from '../../../Image/Carens/car2.jpg'
import car3 from '../../../Image/Carens/car3.jpg';
import car4 from '../../../Image/Carens/car4.jpg'
import safe1 from '../../../Image/Carens/safe1.jpg'
import safe2 from '../../../Image/Carens/safe2.jpg';
import safe3 from '../../../Video/Carens/safe3.mp4';
import safe4 from '../../../Image/Carens/safe4.jpg';
import Cardcarrousel from '../../../Cars/Carens/Component/cardcarrousel'
import BookIcon from '@mui/icons-material/LibraryBooks';
import CallIcon from '@mui/icons-material/Call';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Carensbrochure from '../../../Brochure/Carens/Carens.pdf'
import Faq from '../../../Cars/Carens/Component/faqs';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [activeImageIndex2, setActiveImageIndex2] = useState(0);
  const [currentIndex3, setCurrentIndex3] = useState(0);
  const [activeImageIndex3, setActiveImageIndex3] = useState(0);
  const [currentIndex4, setCurrentIndex4] = useState(0);
  const [activeImageIndex4, setActiveImageIndex4] = useState(0);
  const [currentIndex5, setCurrentIndex5] = useState(0);
  const [activeImageIndex5, setActiveImageIndex5] = useState(0);
  const [currentIndex6, setCurrentIndex6] = useState(0);
  const [activeImageIndex6, setActiveImageIndex6] = useState(0);
  const [currentIndex7, setCurrentIndex7] = useState(0);
  const [activeImageIndex7, setActiveImageIndex7] = useState(0);
  const [currentIndex8, setCurrentIndex8] = useState(0);
  const [activeImageIndex8, setActiveImageIndex8] = useState(0);
  const [visibleCount, setVisibleCount] = useState(3);
  
  const contents = [
    {
      title: 'Dash Cam with Dual Camera',
      description: 'Capture every moment from all angles, ensuring your journey is watched over with clarity and precision.',
      image: gravity1,
    },
    {
      title: 'Black Leatherette Seats',
      description: 'Sink into sleek, sophisticated comfort that enhances every mile of your adventure.',
      image: gravity2,
    },
    {
      title: 'Skylight Sunroof',
      description: 'Open up to endless possibilities as the scenic view elevates your drive.',
      image: gravity3,
    },
    {
      title: 'D-Cut Steering Wheel',
      description: 'Experience refined control with a modern design that adds a touch of precision to every turn.',
      image: gravity4,
    },
    {
      title: 'Gravity Emblem',
      description: 'Wear the emblem of distinction, marking your journey as one that’s from a different world.',
      image: gravity5,
    },
  ];

  const contents2 = [
    {
      title: 'Stylish matte graphite exterior and black glossy grille',
      description: 'Make you stand out in the world',
      image: x1,
    },
    {
      title: 'G1.5 Turbo Petrol Engine with 160 ps power',
      description: 'Delivers unmatched power for your family trips',
      image: x2,
    },
    {
      title: 'Rear seat entertainment screen',
      description: 'Gives freedom to choose your entertainment',
      image: x3,
    },
    {
      title: 'Futuristic interiors with 3-rows, captain seats and walk in lever',
      description: 'Offers more legroom and comfort, available in Kia 6 and 7 seater car options in India, which is class-leading',
      image: x4,
    },
    {
      title: 'Kia Connect Skills with Amazon Alexa',
      description: 'Lets you control your Kia from the comfort of your home',
      image: x5,
    },
  ];

  const contents3 = [
    {
      title: 'Star Map LED DRLs with Crown Jewel LED Headlamps',
      description: 'Inspired by constellations, the Star Map LED DRLs are crafted to enhance forward visibility, while the Crown Jewel LED Headlamps lend a distinctive visual edge through avant-garde design.',
      image: ext1,
    },
    {
      title: 'Star Map LED Tail Lamps',
      description: 'Leave a starry trail wherever you go with the Star Map LED Tail Lamps—a perfect fusion of form and finesse, drawing inspiration from a million stars for its breathtaking aesthetic appeal.',
      image: ext2,
    },
    {
      title: 'R16 – 40.62 cm (16’’) Dual Tone Crystal Cut Alloy Wheels',
      description: 'Chiselled to enhance its distinctive appeal, the dual-tone finish lends a sporty and premium look to the assertive crystal-cut glossy alloy wheels.',
      image: ext3,
    },
    {
      title: 'Kia Signature Tiger Face with Digital Radiator Grille',
      description: "An edge from a different world. Kia's Signature Tiger Face, accompanied by the Digital Radiator Grille, captivates attention with its refreshing style and contrasting attitude.",
      image: ext4,
    },
    {
      title:'Chrome Rear Bumper Garnish with Diamond Knurling Pattern',
      description:'The chrome cast intricate design adds a touch of sophistication to the rear, creating a stunning visual appeal. ',
      image:ext5,
    },
    {
      title:'Sky Light Sunroof',
      description:'Think of it as your portal to a different world.',
      image:ext6,
    },
   
  ];

  const contents4 = [
    {
      title: 'Dashcam with Dual Camera',
      description: 'Enhanced safety, security and convenience with dual recording typically positioned to record both the road ahead and the interior of the Kia Carens.',
      image: int1,
    },
    {
      title: '26.03 cm (10.25’’) HD Touchscreen Navigation',
      description: "The ideal and futuristic way to stay entertained, navigate, and stay connected with the world. ​​",
      image: int2,
    },
    {
      title: 'BOSE Premium Sound System with 8 Speakers',
      description: "Set the mood right and make every journey epic with an unparalleled audio experience.",
      image: int3,
    },
    {
      title: 'Cabin Surround 64-Color Ambient Mood Lighting',
      description: "Every possible shade to match every possible mood.",
      image: int4,
    },
    {
      title:'Ventilated Front Seats',
      description:'Keep it cool as it gets hot outside with the Ventilated Front Seats that make you feel like you are in steering your way through the ',
      image:int5,
    },
    {
      title:'Smart Pure Air Purifier with Virus & Bacteria Protection**',
      description:'Clean cabin-air with protection from harmful virus and bacteria.',
      image:int6,
    },
   
  ];

  const contents5 = [
    {
      title:'Powerful Smartstream 1.5l T-GDi Petrol Engine',
      description: "Unleash the true potential of your drive with the powerful smartstream technology. Transform your journey with G1.5L T-GDi Petrol Engine, delivering the power and performance your drive deserves.",
      image: per1,
    },
    {
      title: 'Advanced Smartstream 1.5l Petrol Engine',
      description: "Powerful journeys are always inspiring but, this time we are unlocking a new level of power with the advanced smartstream technology. The 1.5l Petrol Engine delivers powerful performance where adrenaline does the talking with boosted power and torque.",
      image: per2,
    },
    {
      title: 'Refined 1.5l CRDi VGT Diesel Engine',
      description: "Your unstoppable journey begins when you find inspiration. With the Refined 1.5L CRDi VGT Diesel Engine, experience a drive that redefines excellence and takes your travel to newer heights.",
      image: per3,
    },
   
  ];

  const contents6 = [
    {
      title: '2nd Row One Touch Easy Electric Tumble Seat',
      description:"Quick & easy way to walk straight to the third row with just a touch of button.",
      video: con1,
    },
    {
      title: 'Roof Flushed 2nd and 3rd Row Diffused AC Vents',
      description:"Comfort and convenience come effortlessly with the Carens, featuring first-in-class 2nd and 3rd Row Diffused AC Vents.",
      image: con2,
    },
    {
      title: 'Smartphone Wireless Charger with Cooling Function',
      description:"Designed to wirelessly charge your smartphone with cooling mechanism to prevent overheating during charging. It ensures safe and efficient charging on the go.",
      image: con3,
    },
    {
      title:"Seat Back Table with Cup Holder and Gadget Mount",
      description:"Eat, drink, and savor the journey without any interruptions, thanks to the comfortable Seat Back Table.",
      image:con4,
    },
    {
      title:"Cooling Cup & Can Holders",
      description:"Keep your refreshments chilled on the go with the Cooling Cup and enjoy designated spots for your beverage containers.​",
      image:con5,
    },
  ];

  const contents7 = [
    {
      title: '6 Airbags',
      description: "To enhance passenger safety, all variants come standard with 6 airbags.",
      image: safe1,
    },
    {
      title: 'All Wheel Disc Brakes',
      description: 'Experience an advanced braking system that offers improved safety, control, efficient cooling, consistent performance, and responsive braking on every journey.',
      image: safe2,
    },
    {
      title: 'ESC, VSM, BAS, HAC, DBC & ABS',
      description: "Stay in control during slippery or unstable driving conditions with ESC, VSM, BAS, HAC, DBC and ABS features.",
      video: safe3,
    },
    {
      title: 'Highline Tyre Pressure Monitoring System',
      description: "Receive alerts to help the driver monitor tire pressure, enhancing safety, fuel efficiency, and tire longevity.",
      image: safe4,
    },
    

  ];

  const contents8 = [
    {
      title: 'OTA Map & System Update',
      description: "Equipped with wireless technology, our advanced navigation and infotainment systems keep you updated with minimal disruption to the driver, ensuring convenience.",
      image:car1,
      
    },
    {
      title: 'Voice Controlled Sunroof ',
      description: "Now, drivers and passengers can open, close, or adjust the sunroof with simple voice commands.​",
      image: car2,
    },
    {
      title:'Remotely Controlled Ventilated Seats',
      description: 'Activate the ventilated seats with a remote control to circulate air through the seat surfaces for a more comfortable experience.',
      image: car3,
    },
   
    {
      title: 'Kia Connect Skill on Amazon Alexa',
      description: "Studded with numerous smart features, the Kia Carens keeps you connected even when you're not inside. Switch it on and step into a futuristic journey that begins right there.",
      image: car4,
    },  
  ];


  useEffect(() => {
    const updateVisibleCount = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1024) setVisibleCount(3);
      else if (screenWidth >= 768) setVisibleCount(2);
      else setVisibleCount(1);
    };

    updateVisibleCount();
    window.addEventListener('resize', updateVisibleCount);
    return () => {
      window.removeEventListener('resize', updateVisibleCount);
    };
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + contents.length) % contents.length);
    setActiveImageIndex((prevIndex) => (prevIndex - 1 + contents.length) % contents.length);
  };

  const handlePrev2 = () => {
    setCurrentIndex2((prevIndex2) => (prevIndex2 - 1 + contents2.length) % contents2.length);
    setActiveImageIndex2((prevIndex2) => (prevIndex2 - 1 + contents2.length) % contents2.length);
  };

  const handlePrev3 = () => {
    setCurrentIndex3((prevIndex3) => (prevIndex3 - 1 + contents3.length) % contents3.length);
    setActiveImageIndex3((prevIndex3) => (prevIndex3 - 1 + contents3.length) % contents3.length);
  };

  const handlePrev4 = () => {
    setCurrentIndex4((prevIndex4) => (prevIndex4 - 1 + contents4.length) % contents4.length);
    setActiveImageIndex4((prevIndex4) => (prevIndex4 - 1 + contents4.length) % contents4.length);
  };

  const handlePrev5 = () => {
    setCurrentIndex5((prevIndex5) => (prevIndex5 - 1 + contents5.length) % contents5.length);
    setActiveImageIndex5((prevIndex5) => (prevIndex5 - 1 + contents5.length) % contents5.length);
  };

  const handlePrev6 = () => {
    setCurrentIndex6((prevIndex6) => (prevIndex6 - 1 + contents6.length) % contents6.length);
    setActiveImageIndex6((prevIndex6) => (prevIndex6 - 1 + contents6.length) % contents6.length);
  };

  const handlePrev7 = () => {
    setCurrentIndex7((prevIndex7) => (prevIndex7 - 1 + contents7.length) % contents7.length);
    setActiveImageIndex7((prevIndex7) => (prevIndex7 - 1 + contents7.length) % contents7.length);
  };

  const handlePrev8 = () => {
    setCurrentIndex8((prevIndex8) => (prevIndex8 - 1 + contents8.length) % contents8.length);
    setActiveImageIndex8((prevIndex8) => (prevIndex8 - 1 + contents8.length) % contents8.length);
  };


  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % contents.length);
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % contents.length);
  };

  const handleNext2 = () => {
    setCurrentIndex2((prevIndex2) => (prevIndex2 + 1) % contents2.length);
    setActiveImageIndex2((prevIndex2) => (prevIndex2 + 1) % contents2.length);
  };

  const handleNext3 = () => {
    setCurrentIndex3((prevIndex3) => (prevIndex3 + 1) % contents3.length);
    setActiveImageIndex3((prevIndex3) => (prevIndex3 + 1) % contents3.length);
  };

  const handleNext4 = () => {
    setCurrentIndex4((prevIndex4) => (prevIndex4 + 1) % contents4.length);
    setActiveImageIndex4((prevIndex4) => (prevIndex4 + 1) % contents4.length);
  };

  const handleNext5 = () => {
    setCurrentIndex5((prevIndex5) => (prevIndex5 + 1) % contents5.length);
    setActiveImageIndex5((prevIndex5) => (prevIndex5 + 1) % contents5.length);
  };

  const handleNext6 = () => {
    setCurrentIndex6((prevIndex6) => (prevIndex6 + 1) % contents6.length);
    setActiveImageIndex6((prevIndex6) => (prevIndex6 + 1) % contents6.length);
  };

  const handleNext7 = () => {
    setCurrentIndex7((prevIndex7) => (prevIndex7 + 1) % contents7.length);
    setActiveImageIndex7((prevIndex7) => (prevIndex7 + 1) % contents7.length);
  };

  const handleNext8 = () => {
    setCurrentIndex8((prevIndex8) => (prevIndex8 + 1) % contents8.length);
    setActiveImageIndex8((prevIndex8) => (prevIndex8 + 1) % contents8.length);
  };

  const getVisibleContents = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents[(currentIndex + i) % contents.length]);
    }
    return items;
  };

  const getVisibleContents2 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents2[(currentIndex2 + i) % contents2.length]);
    }
    return items;
  };
  const getVisibleContents3 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents3[(currentIndex3 + i) % contents3.length]);
    }
    return items;
  };
  const getVisibleContents4 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents4[(currentIndex4 + i) % contents4.length]);
    }
    return items;
  };
  const getVisibleContents5 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents5[(currentIndex5 + i) % contents5.length]);
    }
    return items;
  };
  const getVisibleContents6 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents6[(currentIndex6 + i) % contents6.length]);
    }
    return items;
  };
  const getVisibleContents7 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents7[(currentIndex7 + i) % contents7.length]);
    }
    return items;
  };
  const getVisibleContents8 = () => {
    const items = [];
    for (let i = 0; i < visibleCount; i++) {
      items.push(contents8[(currentIndex8 + i) % contents8.length]);
    }
    return items;
  };


  const handleTextClick = (index) => {
    const actualIndex = (currentIndex + index) % contents.length;
    setActiveImageIndex(actualIndex);
  };

  const handleTextClick2 = (index) => {
    const actualIndex = (currentIndex2 + index) % contents2.length;
    setActiveImageIndex2(actualIndex);
  };

  const handleTextClick3 = (index) => {
    const actualIndex = (currentIndex3 + index) % contents3.length;
    setActiveImageIndex3(actualIndex);
  };
  const handleTextClick4 = (index) => {
    const actualIndex = (currentIndex4 + index) % contents4.length;
    setActiveImageIndex4(actualIndex);
  };
  const handleTextClick5 = (index) => {
    const actualIndex = (currentIndex5 + index) % contents5.length;
    setActiveImageIndex5(actualIndex);
  };
  const handleTextClick6 = (index) => {
    const actualIndex = (currentIndex6 + index) % contents6.length;
    setActiveImageIndex6(actualIndex);
  };
  const handleTextClick7 = (index) => {
    const actualIndex = (currentIndex7 + index) % contents7.length;
    setActiveImageIndex7(actualIndex);
  };
  const handleTextClick8 = (index) => {
    const actualIndex = (currentIndex8 + index) % contents8.length;
    setActiveImageIndex8(actualIndex);
  };

  return (
    <Box sx={{}}>

      {/*CAROUSEL1- GRAVITY */}
      <div className="carousel-container">
        <Box sx={{ position: 'relative', width: '100%', height: { md: '100%', xs: '100%' }, color: '#fff' }}>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
              GRAVITY
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
              Step Into a World Beyond.
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
              The Carens Gravity Variant invites you to explore a world where luxury and innovation meet. With design, tech, and a driving experience that transcends the every day, it truly is from a different world.​
            </Typography>
          </Box>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex === (currentIndex + index) % contents.length ? 'active' : ''}`}
                onClick={() => handleTextClick(index)}
                style={{height:"150px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents[activeImageIndex].image} alt={contents[activeImageIndex].title} />
        </div>
      </div>

      {/*CAROUSEL2- XLINE */}
      <div className="carousel-container">
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
            X-LINE
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            Go Beyond the Ordinary.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            X-line speaks to a bold attitude that embraces everything with confidence. With a unique flair that stands apart, this variant is for those who want to leave an impression wherever they go.
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev2} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext2} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents2().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex2 === (currentIndex2 + index) % contents2.length ? 'active' : ''}`}
                onClick={() => handleTextClick2(index)}
                style={{height:"150px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents2[activeImageIndex2].image} alt={contents2[activeImageIndex2].title} />
        </div>
      </div>

      {/* SHOWINTEREST FORM Section */}
      <ShowInterest />

      {/* CAROUSEL3-EXTERIOR */}
      <div className="carousel-container">
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
            EXTERIOR
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            A world of bold and captivating style
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            The distinctive style of Kia Carens inspires awe and respect at the same time. Just like you, this car commands instant attention.
            It has been designed with bold attitude and space-age sophistication to offer experiences from a different world.
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev3} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext3} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents3(contents3, currentIndex3).map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex3 === (currentIndex3 + index) % contents3.length ? 'active' : ''}`}
                onClick={() => handleTextClick3(index)}
                style={{height:"180px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents3[activeImageIndex3].image} alt={contents3[activeImageIndex3].title} />
        </div>
      </div>

      {/* CAROUSEL 4 - INTERIOR */}
      <div className="carousel-container">
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
            INTERIOR
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'Kia-sig', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            A world of intuitive and immersive experiences
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'Roboto, sans-serif', fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>
            When you look within yourself, you are truly limitless. The Kia Carens is an ode to that spirit. Right from the spacecraft-like plush seats to the wraparound high-tech dashboard, every detail of the Kia Carens is crafted perfectly to immerse you in a different world of advancement.
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev4} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext4} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents4(contents4, currentIndex4).map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex4 === (currentIndex4 + index) % contents4.length ? 'active' : ''}`}
                onClick={() => handleTextClick4(index)}
                style={{height:"165px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents4[activeImageIndex4].image} alt={contents4[activeImageIndex4].title} />
        </div>
      </div>

      {/* CAROUSEL 5 - PERFORMANCE */}
      <div className="carousel-container">
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
            PERFORMANCE
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'Kia-sig', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
          A world where adrenaline does the talking
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'Roboto, sans-serif', fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>
          Excitement seeks those who seek power. Level up your driving experience with the smartstream powertrain, delivering unmatched power and performance.
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev5} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext5} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents5(contents5, currentIndex5).map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex5 === (currentIndex5 + index) % contents5.length ? 'active' : ''}`}
                onClick={() => handleTextClick5(index)}
                style={{height:"240px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents5[activeImageIndex5].image} alt={contents5[activeImageIndex5].title} />
        </div>
      </div>

      {/* CAROUSEL 6 - CONVENIENCE */}
      <div className="carousel-container">
        <Box sx={{ position: 'relative', width: '100%', height: { md: '100%', xs: '100%' }, color: '#fff' }}>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
            CONVENIENCE
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            A world where comfort is everything
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            Space is what inspires you to be truly you. That’s why the Carens offers you class-leading cabin space with plush 3-row seating comfort in 6 & 7 seater options to carry your world with you.​
            </Typography>
          </Box>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev6} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext6} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents6().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex6 === (currentIndex6 + index) % contents6.length ? 'active' : ''}`}
                onClick={() => handleTextClick6(index)}
                style={{height:"230px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image/Video Section */}
        <div
            className="carousel-image"
            style={{
              position: "relative", 
              marginBottom: "20px", 
              width: "100%", 
              height: "auto",
            }}
          >
            {contents6[activeImageIndex6].video ? (
              <video
                key={activeImageIndex6}
                playsInline
                autoPlay
                loop
                muted
                preload="auto"
                style={{
                  position: "relative", 
                  width: "100%", 
                  height: "auto", 
                  objectFit: "cover", 
                }}
              >
                <source src={contents6[activeImageIndex6].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={contents6[activeImageIndex6].image}
                alt={contents6[activeImageIndex6].title}
                style={{
                  width: "100%", 
                  height: "auto", 
                }}
              />
            )}
         </div>
      </div>


      {/* CAROUSEL7- SAFETY */}
      <div className="carousel-container">
        <Box sx={{ position: 'relative', width: '100%', height: { md: '100%', xs: '100%' }, color: '#fff' }}>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '18px', md: '22px' } }}>
            SAFETY
            </Typography>
            <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'KIAbodyLatinbasic_Bold', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
            A world that offers supreme safety
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'KIAbodyLatinbasic_Regular', fontSize: { xs: '14px', sm: '17px', md: '18px' } }}>
            The Kia Carens comes with class-leading Robust 10 Hi-safety features along with many additional features as standard across all variants, making it the safest car in its class. After all, your safety is what drives us to think out of the box.
            </Typography>
          </Box>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev7} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext7} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents7().map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex7 === (currentIndex7 + index) % contents7.length ? 'active' : ''}`}
                onClick={() => handleTextClick7(index)}
                style={{height:"190px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image/Video Section */}
        <div
            className="carousel-image"
            style={{
              position: "relative", 
              marginBottom: "20px", 
              width: "100%", 
              height: "auto",
            }}
          >
            {contents7[activeImageIndex7].video ? (
              <video
                key={activeImageIndex7}
                playsInline
                autoPlay
                loop
                muted
                preload="auto"
                style={{
                  position: "relative", 
                  width: "100%", 
                  height: "auto", 
                  objectFit: "cover", 
                }}
              >
                <source src={contents7[activeImageIndex7].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={contents7[activeImageIndex7].image}
                alt={contents7[activeImageIndex7].title}
                style={{
                  width: "100%", 
                  height: "auto", 
                }}
              />
            )}
         </div>
      </div>


      {/* CAROUSEL8- CONNECTED */}
      <div className="carousel-container">
        <Box sx={{ padding: 5 }}>
          <Typography variant="h6" color="#959191" sx={{ fontWeight: 'bold', fontFamily: "KIAbodyLatinbasic_Bold", fontSize: { xs: '18px', md: '22px' } }}>
          CONNECTED
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'black', marginTop: '10px', fontFamily: 'Kia-sig', fontSize: { xs: '25px', md: '35px', sm: '32px' } }}>
          Smarter than ever
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1, width: { md: '80%', xs: '90%' }, color: 'black', fontFamily: 'Roboto, sans-serif', fontSize: { xs: '12px', sm: '15px', md: '16px' } }}>
          Indulge in a world where the Carens is always at your beck-and-call.
          Presenting, the next-gen Kia Connect that's integrated with Alexa and offers 66 smart features that you can operate from the comfort of your home.
          </Typography>
        </Box>

        {/* Navigation Section */}
        <div className="carousel-navigation">
          <button className="arrow-left" onClick={handlePrev8} aria-label="Previous"><ArrowBackIosIcon /></button>
          <button className="arrow-right" onClick={handleNext8} aria-label="Next"><ArrowForwardIosIcon /></button>
        </div>

        {/* Content Section */}
        <div className="carousel-content">
          {getVisibleContents8(contents8, currentIndex8).map((content, index) => (
            <div key={index} className="carousel-item-container">
              <div
                className={`carousel-item ${activeImageIndex8 === (currentIndex8 + index) % contents8.length ? 'active' : ''}`}
                onClick={() => handleTextClick8(index)}
                style={{height:"200px"}}
              >
                <h3>{content.title}</h3>
                <p>{content.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Image Section */}
        <div className="carousel-image">
          <img src={contents8[activeImageIndex8].image} alt={contents8[activeImageIndex8].title} />
        </div>
      </div>

           {/* cardcarrousel */}
           <Cardcarrousel/>

            {/* Info */}
            <Box sx={{ padding: '50px 0', textAlign: 'center' }}>
            <Typography 
            variant="h5" 
            color="text.secondary" 
            sx={{ fontWeight: 'bold', mb: 5,fontFamily:'Kia-sig', }}
            >
            DETAILED INFORMATION
            </Typography>

            <Grid container spacing={4} justifyContent="center">
            
            {/* Download Brochure Card */}
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  bgcolor: '#0b1d28',
                  color: '#fff',
                  padding: '20px',
                  borderRadius: '4px',
                }}
              >
                <PictureAsPdfIcon sx={{ fontSize: 60, color: 'white', mb: 2 }} />
                <Typography variant="h6" fontWeight="bold" sx={{fontFamily:'Kia-sig'}}>
                  Download Brochure
                </Typography>
                <Typography color="white" sx={{ mb: 2,fontFamily:'Kia-sig' }}>
                  Know it all about our Big Family Car
                </Typography>
                <Button 
                  variant="contained" 
                  color="secondary" 
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = Carensbrochure; 
                    link.download = 'carens_brochure.pdf';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  }}
                >
                  Download
                </Button>
              </Box>
            </Grid>

            {/* Call Us Card */}
            <Grid item xs={12} md={4}>
              <Box>
                <CallIcon sx={{ fontSize: 60, color: 'black', mb: 2 }} />
                <Typography variant="h6" fontWeight="bold" fontFamily="Kia-sig">
                  Call us for more information
                </Typography>
                <Typography color="text.secondary" sx={{ mb: 1,fontFamily:'Kia-sig' }}>
                  We’ll be happy to answer all your queries.
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" fontFamily="Kia-sig">
                09237386213
                </Typography>
                <Button 
                  variant="outlined" 
                  color="inherit" 
                  onClick={() => {
                    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
                    if (isMobile) {
                      window.location.href = 'tel: 09237386213';
                    } else {
                      alert('Please call  09237386213 for more information.');
                    }
                  }}
                >
                  Call Us
                </Button>
              </Box>
            </Grid>
            </Grid>
            </Box>

            {/* Faq */}
            <Faq/>


    </Box>
  );
};

export default Carousel;
