import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Card,
  CardContent,
  IconButton,
  useMediaQuery,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
import CarRentalIcon from "@mui/icons-material/CarRental";
import { BsPeopleFill } from "react-icons/bs";
import { AiOutlineDashboard } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import centralLogo from "../../Pages/Image/download.jpg";
import logoImage from "../../Pages/Image/knlogo.jpg";
import { Bar } from "react-chartjs-2";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

// Register Chart.js components
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const drawerWidth = 240;

const Dashboard = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setIsAnchorEl] = useState(null);
  const [open, setIsOpen] = useState(false);

  const handleMenuOpen = (event) => {
    setIsAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsAnchorEl(null);
    setIsOpen(false);
  };
  const [contactData, setContactData] = useState({
    labels: [],
    datasets: [
      {
        label: "Contacts",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  });
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Contacts",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  const [bookTestDriveData, setTestDriveData] = useState({
    labels: [],
    datasets: [
      {
        label: "Test Drive",
        data: [],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
      },
    ],
  });
  const options1 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Test Drive",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  useEffect(() => {
    props.viewOldCarLength(props.login.token, props.login.user_id);
    props.viewTestDriveLength(props.login.token, props.login.user_id);
    props.viewContactUsLength(props.login.token, props.login.user_id);
    if (props.client.viewContactUsLength) {
      const { labels, counts } = props.client.viewContactUsLength;

      setContactData({
        labels: labels, // Set labels from API response
        datasets: [
          {
            label: "Contacts",
            data: counts, // Set data from counts in API response
            backgroundColor: "rgba(255, 99, 132, 0.6)",
          },
        ],
      });
    }
    if (props.client.viewTestdriveLength) {
      const { labels, counts1 } = props.client.viewTestdriveLength;

      setTestDriveData({
        labels: labels, // Set labels from API response
        datasets: [
          {
            label: "TestDrive",
            data: counts1, // Set data from counts in API response
            backgroundColor: "rgba(54, 162, 235, 0.6)",
          },
        ],
      });
    }
  }, [
    props.login.token,
    props.login.user_id,
    props.client.viewContactUsLength,
    props.client.viewTestdriveLength,
  ]);

  const drawer = (
    <List sx={{ marginTop: "100px", fontFamily:"KIAbodyLatinbasic_Regular" }} className="KIAbodyLatinbasic_Regular">
      {[
        { text: "Dashboard", route: "/admin" },
        { text: "Old Cars", route: "/oldcar" },
        { text: "Advertisement", route: "/advertisement" },
        { text: "Book Test Drive", route: "/book-test-drive" },
        { text: "Contact", route: "/contact-details" },
        { text: "Service Request", route: "/service-request" },
        { text: "Vehicle Enquiry", route: "/vehicle-enquiry" },
        { text: "Enquiry", route: "/enquiry" },
        { text: "Quatation", route: "/quatation" },
      ].map((item, index) => (
        <ListItem button key={item.text} onClick={() => navigate(item.route)} sx={{fontFamily:"KIAbodyLatinbasic_Regular"}} className="KIAbodyLatinbasic_Regular">
          <ListItemIcon sx={{ color: "#fff" }}>
            {index === 0 && <AiOutlineDashboard />}
            {index === 1 && <TimeToLeaveIcon />}
            {index === 2 && <ConnectedTvIcon />}
            {index === 3 && <CarRentalIcon />}
            {index === 4 && <FiUsers />}
            {index === 5 && <MiscellaneousServicesIcon />}
            {index === 6 && <MiscellaneousServicesIcon />}
            {index === 7 && <MiscellaneousServicesIcon />}
            {index === 8 && <MiscellaneousServicesIcon />}
          </ListItemIcon>
          <ListItemText primary={item.text}  sx={{fontFamily:"KIAbodyLatinbasic_Regular"}} className="KIAbodyLatinbasic_Regular"/>
        </ListItem>
      ))}
    </List>
  );

  return (
    <Box sx={{ display: "flex", height: "auto" }}
    className="KIAbodyLatinbasic_Regular"
    >
      {" "}
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          height: "60px",
          zIndex: 1201,
        }}
      >
        <Toolbar
          sx={{
            height: "60px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px",
          }}
        >
          {isMobile && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          {/* Logo Section */}
          <Box
            component={RouterLink}
            to="/admin"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={logoImage}
              alt="mg"
              style={{
                width: "60px",
                height: "auto",
                marginRight: "8px",
              }}
            />
            <img
              src={centralLogo}
              alt="central group"
              style={{
                width: "120px",
                height: "auto",
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar
              alt="User Avatar"
              src={props.login?.profile_pic || props.login?.name}
              sx={{ width: 40, height: 40, cursor: "pointer" }}
              onClick={handleMenuOpen}
            />
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                component={RouterLink}
                to="/profile"
                onClick={handleMenuClose}
              >
                <AccountCircleIcon sx={{ marginRight: 1,fontFamily:"KIAbodyLatinbasic_Regular" }} />
                Profile
              </MenuItem>
              <MenuItem
                component={RouterLink}
                to="/admin"
                onClick={(e) => {
                  props.onLogout();
                  handleMenuClose();
                }}
              >
                <LogoutIcon sx={{ marginRight: 1,fontFamily:"KIAbodyLatinbasic_Regular" }} />
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {/* Sidebar for Desktop */}
      {!isMobile && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#101010",
              color: "#fff",
              height: "100vh",
              fontFamily:"KIAbodyLatinbasic_Regular"
            },
          }}
          variant="permanent"
          anchor="left"
        >
          {drawer}
        </Drawer>
      )}
      {/* Sidebar for Mobile */}
      <Drawer
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#101010",
            color: "#fff",
            height: "auto",
          },
        }}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        anchor="left"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px",
          }}
        >
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
        {drawer}
      </Drawer>
      {/* Main Content */}
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "#000", paddingBottom: "20px" }}
      >
        <Toolbar />
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: "#fff",
            fontFamily:"KIAbodyLatinbasic_Regular"
          }}
        >
          DASHBOARD
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <Card sx={{ backgroundColor: "#3498db", color: "#fff" }}>
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontSize: "25px", fontWeight: "800",fontFamily:"KIAbodyLatinbasic_Regular" }}
                >
                  OLD CARS
                </Typography>
                <TimeToLeaveIcon size={32} />
                <Typography variant="h3" sx={{ fontSize: "20px",fontFamily:"KIAbodyLatinbasic_Regular" }}>
                  Number of Old Cars: {props.client.oldcarlength || 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Card sx={{ backgroundColor: "#9b59b6", color: "#fff" }}>
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontSize: "25px", fontWeight: "800",fontFamily:"KIAbodyLatinbasic_Regular" }}
                >
                  TEST DRIVES
                </Typography>
                <CarRentalIcon size={32} />
                <Typography variant="h3" sx={{ fontSize: "20px",fontFamily:"KIAbodyLatinbasic_Regular" }}>
                  Number of Test Drives:{" "}
                  {props.client.viewTestdriveLength?.totalLength || 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Card sx={{ backgroundColor: "#e67e22", color: "#fff" }}>
              <CardContent
                sx={{ display: "flex", flexDirection: "column", gap: "15px" }}
              >
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontSize: "25px", fontWeight: "800",fontFamily:"KIAbodyLatinbasic_Regular" }}
                >
                  CONTACTS
                </Typography>
                <BsPeopleFill size={32} />
                <Typography variant="h3" sx={{ fontSize: "20px",fontFamily:"KIAbodyLatinbasic_Regular" }}>
                  Number of Contacts:{" "}
                  {props.client.viewContactUsLength?.totalLength || 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Charts Section */}
        <Grid container spacing={3} sx={{ marginTop: "20px", bgcolor: "#000" }}>
          <Grid item xs={12} sm={12} md={6}>
            <Card sx={{ backgroundColor: "#1abc9c", color: "#fff" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "800", marginBottom: "10px",fontFamily:"KIAbodyLatinbasic_Regular" }}
                >
                  Book Test Drive
                </Typography>
                <Bar data={bookTestDriveData} options={options1} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Card sx={{ backgroundColor: "#c0392b", color: "#fff" }}>
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontWeight: "800", marginBottom: "10px",fontFamily:"KIAbodyLatinbasic_Regular" }}
                >
                  Contact
                </Typography>
                <Bar data={contactData} options={options} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
