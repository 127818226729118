import React from "react";
import { Typography, Button, Box, Grid, Card } from "@mui/material";
import Video from "../../../Video/video.mp4";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import Colorsel from "./color";
import DescriptionIcon from "@mui/icons-material/Description";
import Seltos1 from "../../../Image/Seltos1.jpg";
import Seltosbrochure from "../../../Brochure/Seltos/Seltos.pdf";
// import Footer from '../../../Footer/Component/Footer';
import SlidingSeltos from "../../../Cars/Seltos/Component/Slidingseltos";
import Seltosfullimage from "../../../Image/Seltos/seltosgravity.jpg";
import Contentcarrousel1 from "../../../Cars/Seltos/Component/Content1carrousel";
import Wsp from "../../../contact/Component/Floatingwsp";

const Seltos = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      {/* Banner video */}
      <Box
        sx={{
          position: "relative",
          height: "100vh",
          overflowX: "hidden",
          marginTop: "40px",
        }}
      >
        <video
          playsInline
          autoPlay
          loop
          muted
          preload="auto"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        >
          <source src={Video} type="video/mp4" />
        </video>
      </Box>

      <Wsp />

      <Box sx={{ backgroundColor: "#FFFFFF" }}>
        {/* Secord card section */}
        <Box sx={{ padding: "20px", maxWidth: "1200px", margin: "0 auto" }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={12} md={6}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                  boxShadow: 3,
                  gap: "15px",
                }}
              >
                <DriveEtaIcon sx={{ fontSize: "40px", color: "#000" }} />
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: "10px",
                    fontWeight: "bold",
                    fontFamily: "KIAbodyLatinbasic_Bold",
                  }}
                >
                  Register your interest
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#848484",
                    fontFamily: "KIAbodyLatinbasic_Bold",
                    fontSize: { xs: "13px", sm: "15px" },
                  }}
                >
                  Fill in the required details by clicking here, We will contact
                  you as soon as possible.
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "10px",
                    backgroundColor: "#E50063",
                    color: "white",
                    borderRadius: "8px",
                    height: "50px",
                    fontWeight: "bold",
                    fontFamily: "KIAbodyLatinbasic_Bold",
                    "&:hover": {
                      backgroundColor: "#E50063",
                    },
                  }}
                  onClick={() => {
                    const element = document.getElementById("showinterest");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  Click Here
                </Button>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px",
                  boxShadow: 3,
                  gap: "15px",
                }}
              >
                <DescriptionIcon sx={{ fontSize: "40px", color: "#000" }} />
                <Typography
                  variant="h6"
                  sx={{
                    marginTop: "10px",
                    fontWeight: "bold",
                    fontFamily: "KIAbodyLatinbasic_Bold",
                  }}
                >
                  Download Brochure
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#848484",
                    fontSize: { xs: "13px", sm: "15px" },
                    fontFamily: "KIAbodyLatinbasic_Bold",
                  }}
                >
                  Click here to download the Brochure and price list of all new
                  Seltos on your device
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "10px",
                    backgroundColor: "#E50063",
                    color: "white",
                    borderRadius: "8px",
                    height: "50px",
                    fontWeight: 800,
                    fontFamily: "KIAbodyLatinbasic_Bold",
                    "&:hover": {
                      backgroundColor: "#E50063",
                    },
                  }}
                  component="a"
                  href={Seltosbrochure}
                  download="seltos_brochure.pdf"
                >
                  Click To Download
                </Button>
              </Card>
            </Grid>
          </Grid>
        </Box>

        {/* Content Section */}
        <Box sx={{ padding: "40px 20px" }}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* Description*/}
            <Grid item xs={12}>
              <Box sx={{ padding: { xs: "10px", sm: "20px", md: "25px" } }}>
                <Typography
                  variant="h4"
                  sx={{
                    marginBottom: "20px",
                    fontSize: { xs: "16px", md: "18px" },
                    color: "#959191",
                    paddingLeft: { md: 6, xs: 2, sm: 4 },
                    paddingRight: "40px",
                    fontFamily: "KIAbodyLatinbasic_Bold",
                    fontWeight: 800,
                  }}
                >
                  UNLOCK THE NEXT-LEVEL
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    marginBottom: "20px",
                    fontSize: { xs: "24px", md: "30px" },
                    paddingLeft: { md: 6, xs: 2, sm: 4 },
                    paddingRight: "40px",
                    fontFamily: "KIAbodyLatinbasic_Bold",
                    fontWeight: "bolder",
                  }}
                >
                  Level up with the Badass
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "20px", sm: "18px" },
                    marginBottom: "20px",
                    paddingLeft: { md: 6, xs: 2, sm: 4 },
                    paddingRight: "70px",
                    fontFamily: "KIAbodyLatinbasic_Regular",
                  }}
                >
                  Dominating. Unyielding. Confident. The new Kia Seltos is here
                  to take you on an exhilarating drive experience. Strongly
                  chiseled looks and a formidable stance highlight its badass
                  traits at the very first sight. With highly powerful
                  fuel-efficient engine options and 32 safety features that
                  include 17 autonomous features of Advanced Driver Assistance
                  System (ADAS)* Level 2 for your enhanced protection, the
                  reinvented Seltos has everything it takes to be a badass on
                  the road.
                </Typography>
              </Box>
            </Grid>

            {/* Image Section */}
            <Grid item xs={12}>
              <Box
                component="img"
                src={Seltos1}
                alt="Car Model"
                sx={{
                  width: "100%",
                  height: "auto",
                  maxHeight: { xs: "300px", md: "700px" },
                  objectFit: "cover",
                }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* color */}
        <Colorsel />

        {/* Sliding Seltos */}
        <SlidingSeltos />

        {/* Full-sized image */}
        <Box
          sx={{
            width: "100vw",
            height: { md: "90vh", xs: "40vh", sm: "60vh" },
            overflow: "hidden",
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              height: { md: "90vh", xs: "40vh", sm: "60vh" },
              backgroundImage: `url(${Seltosfullimage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                color: "#fff",
                textShadow: "1px 1px 4px rgba(0, 0, 0, 0.7)",
              }}
            ></Grid>
          </Grid>
        </Box>

        {/* All content carrousels */}
        <Contentcarrousel1 />

      </Box>
    </Box>
  );
};

export default Seltos;
