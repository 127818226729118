import React from "react";
import { Box, Typography, Container, Grid, IconButton } from "@mui/material";
import carimage from "../../Image/DJI_0909.jpg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link } from "react-router-dom";
import "../../../App.css";
import Wsp from '../../contact/Component/Floatingwsp';

const AboutUs = () => {
  

  return (
    <Box sx={{ fontFamily: "KIAbodyLatinbasic_Regular", marginTop: "45px" }}>
      {/* Links to home and this page */}
      <Box
        sx={{
          height: "40px",
          marginLeft: "50px",
          marginTop: "8px",
          display: "flex",
          gap: "15px",
          alignItems: "center",
          paddingTop:"10px"
        }}
      >
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "#5A80C2",
            cursor: "pointer",
          }}
        >
          <Typography
            variant="body1"
            className="KIAbodyLatinbasic_Bold"
            sx={{ fontFamily: "KIAbodyLatinbasic_Bold" }}
          >
            Home
          </Typography>
        </Link>
        <ArrowForwardIosIcon sx={{ fontSize: "16px", color: "grey" }} />
        <Link
          to="/about"
          style={{ textDecoration: "none", color: "grey", cursor: "pointer" }}
        >
          <Typography
            variant="body1"
            className="KIAbodyLatinbasic_Bold"
            sx={{ fontFamily: "KIAbodyLatinbasic_Bold" }}
          >
            About Kia Centeral
          </Typography>
        </Link>
      </Box>
      {/* Backimage Section */}
      <Box
        sx={{
          backgroundImage: `url(${carimage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: { xs: "50vh", md: "140vh", sm: "100vh" },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#fff",
          textAlign: "center",
        }}
      ></Box>

      {/* About Section */}
      <Container sx={{ py: 8 }}>
        <Typography
          variant="h6"
          className="KIAbodyLatinbasic_Bold"
          sx={{
            fontFamily: "KIAbodyLatinbasic_Bold",
            textAlign: "center",
            fontSize: "center",
            mb: 4,
          }}
        >
          Central Kia: Redefining Driving Excellence in Odisha with <br/>
          <span  style={{fontSize:"17px"}}>CONSORTIUM MOTORS PVT LTD</span>
        </Typography>
        <Typography
          variant="body2"
          className="KIAbodyLatinbasic_Regular"
          sx={{
            textAlign: "center",
            maxWidth: "800px",
            margin: "0 auto",
            fontFamily: "KIAbodyLatinbasic_Regular",
           
          }}
        >
          <span style={{fontWeight:"bold"}}>CONSORTIUM MOTORS PVT LTD,</span> operating under the brand <span style={{fontWeight:"bold"}} >Central Kia,</span> is
          committed to bringing the best of Kia’s innovative and stylish
          vehicles to Odisha. Our head office is located at PLOT NO-655 - 656,
          NH-16, NAKHARA, BALIANTA, BHUBANESWAR, Khordha, Odisha, 752101 India.
          The Central Kia showroom is situated at Plot No. 644/2148, NH-16,
          Pahal, Bhubaneswar, Odisha 752101. We offer a wide range of Kia
          vehicles, delivering exceptional performance, cutting-edge technology,
          and modern design to enhance your driving experience.
        </Typography>
      </Container>

      {/* Why Choose Us Section */}
      <Box sx={{ bgcolor: "#f4f4f4", py: 8 }}>
        <Container>
          <Typography
            variant="h4"
            className="KIAbodyLatinbasic_Bold"
            sx={{
              fontFamily: "KIAbodyLatinbasic_Bold",
              textAlign: "center",
              mb: 6,
            }}
          >
            Why Choose Central Kia?
          </Typography>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            className="KIAbodyLatinbasic_Regular"
            sx={{ fontFamily: "KIAbodyLatinbasic_Regular" }}
          >
            {[
              {
                title: "Authorized Kia Dealership",
                desc: "Experience the reliability of an official Kia partner, offering genuine vehicles and services directly from the brand.",
              },
              {
                title: "Wide Range of Models",
                desc: "Discover a diverse lineup of Kia vehicles, from stylish sedans to versatile SUVs, tailored to suit every lifestyle.",
              },
              {
                title: "State-of-the-Art Showroom",
                desc: "Visit our modern showroom, designed to provide a comfortable and engaging car-buying experience.",
              },
              {
                title: "Exceptional Customer Service",
                desc: "Our dedicated team ensures a seamless journey, from selecting your vehicle to after-sales support.",
              },
            ].map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography
                    variant="h6"
                    className="KIAbodyLatinbasic_Bold"
                    sx={{ fontFamily: "KIAbodyLatinbasic_Bold" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="KIAbodyLatinbasic_Regular"
                    sx={{ mt: 1, fontFamily: "KIAbodyLatinbasic_Regular" }}
                  >
                    {item.desc}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Mission and Vision Section */}
      <Container sx={{ py: 8 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              className="KIAbodyLatinbasic_Bold"
              sx={{ fontFamily: "KIAbodyLatinbasic_Bold", mb: 2 }}
            >
              Our Mission
            </Typography>
            <Typography
              variant="body2"
              className="KIAbodyLatinbasic_Regular"
              sx={{ fontFamily: "KIAbodyLatinbasic_Regular" }}
            >
              To deliver exceptional automotive experiences by offering
              world-class Kia vehicles, superior customer service, and reliable
              after-sales support. We aim to foster trust, innovation, and
              satisfaction, ensuring our customers enjoy a seamless and
              fulfilling journey with Central Kia.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              className="KIAbodyLatinbasic_Bold"
              sx={{ fontFamily: "KIAbodyLatinbasic_Bold", mb: 2 }}
            >
              Our Vision
            </Typography>
            <Typography
              variant="body2"
              className="KIAbodyLatinbasic_Regular"
              sx={{ fontFamily: "KIAbodyLatinbasic_Regular" }}
            >
              To be the leading Kia dealership in Odisha, setting new benchmarks
              in customer satisfaction, sustainability, and technological
              advancement. We envision empowering our customers with innovative
              mobility solutions that enhance their lifestyle and contribute to
              a greener future.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {/* content-why Ev */}
      {/* <Box
        sx={{
          marginBottom: "10px",
          padding: { md: "0px 150px 0px 150px", xs: "0px 30px 0px 30px" },
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          className="KIAbodyLatinbasic_Bold"
          sx={{
            fontWeight: "bold",
            marginBottom: 2,
            fontFamily: "KIAbodyLatinbasic_Bold",
            marginTop: "10px",
            fontSize: { md: "22px", sm: "18px", xs: "16px" },
            textAlign: "center",
          }}
        >
          Why Electric Vehicles (EVs)?
        </Typography>
        <Typography
          variant="body1"
          className="KIAbodyLatinbasic_Regular"
          sx={{
            marginBottom: 2,
            fontSize: { sm: "16px", xs: "14px" },
            fontFamily: "KIAbodyLatinbasic_Regular",
            textAlign: "center",
          }}
        >
          Switching to electric vehicles is not just a lifestyle upgrade—it’s a
          commitment to a better tomorrow. EVs reduce greenhouse gas emissions,
          lower running costs, and offer innovative features for a superior
          driving experience. At Central Kia, we make this transition smooth and
          rewarding for you.
        </Typography>
      </Box> */}

      {/* Visit Section */}
      <Box sx={{ bgcolor: "#000", color: "#fff", py: 8 }}>
        <Container>
          <Typography
            variant="h4"
            className="KIAbodyLatinbasic_Bold"
            sx={{
              fontFamily: "KIAbodyLatinbasic_Bold",
              textAlign: "center",
              mb: 4,
            }}
          >
            Visit Central Kia Today
          </Typography>
          <Typography
            variant="body2"
            className="KIAbodyLatinbasic_Regular"
            sx={{
              textAlign: "center",
              maxWidth: "700px",
              margin: "0 auto",
              fontFamily: "KIAbodyLatinbasic_Regular",
            }}
          >
            Experience the perfect combination of style, innovation, and
            performance at Central Kia. Our dedicated team is here to guide you
            through our wide range of vehicles, assist with financing options,
            and provide you with an unforgettable test drive experience.
          </Typography>
          <br />
          <br />
          <Typography
            variant="body2"
            className="KIAbodyLatinbasic_Regular"
            sx={{
              textAlign: "center",
              maxWidth: "700px",
              margin: "0 auto",
              fontFamily: "KIAbodyLatinbasic_Regular",
            }}
          >
            Join the Kia family and elevate your driving journey with
            cutting-edge technology and unmatched comfort. Visit us at Plot No.
            644/2148, NH-16, Pahal, Bhubaneswar, Odisha 752101, and discover the
            joy of driving with Central Kia today!
          </Typography>
        </Container>
      </Box>

      {/* <IconButton
        onClick={handleWhatsAppClick}
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          backgroundColor: "#25D366",
          color: "#fff",
          width: 56,
          height: 56,
          boxShadow: 3,
          "&:hover": {
            backgroundColor: "#1DA955",
          },
        }}
      >
        <WhatsAppIcon sx={{ fontSize: 30 }} />
      </IconButton> */}
      <Wsp/>
    </Box>
  );
};

export default AboutUs;
