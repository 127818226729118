import React, { useState } from 'react';
import { Card, CardContent, TextField, Button, Box, Typography, Grid,FormControl,Select,InputLabel,MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Specification(props) {
  const [engine, setEngine] = useState('');
  const [maxPower, setMaxPower] = useState('');
  const [maxTorque, setMaxTorque] = useState('');
  const [mileage, setMileage] = useState('');
  const [driveTrain, setDriveTrain] = useState('');

  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [wheelbase, setWheelbase] = useState('');
  const [groundClearance, setGroundClearance] = useState('');
  const [kerbWeight, setKerbWeight] = useState('');

  const [doors, setDoors] = useState('');
  const [seatingCapacity, setSeatingCapacity] = useState('');
  const [rows, setRows] = useState('');
  const [bootspace, setBootspace] = useState('');
  const [fuelTankCapacity, setFuelTankCapacity] = useState('');

  const [frontSuspension, setFrontSuspension] = useState('');
  const [rearSuspension, setRearSuspension] = useState('');
  const [frontbrakeType, setFrontBrakeType] = useState('');
  const [rearBrakeType, setRearBrakeType] = useState('');
  const [minTurningRadius, setMinTurningRadius] = useState('');
  const [steeringType, setSteeringType] = useState('');
  const [wheels, setWheels] = useState('');
  const [spareWheel, setSpareWheel] = useState('');
  const [frontTyres, setFrontTyres] = useState('');
  const [rearTyres, setRearTyres] = useState('');

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit the form data to the parent component or API
    if (props.client.oldCarId !== "") {
    props.addSpecification(
        props.login.token,props.login.user_id,props.client.oldCarId,
      engine, 
      maxPower, 
      maxTorque, 
      mileage,
       driveTrain, 
      length, width, height, wheelbase, groundClearance, kerbWeight,
      doors, seatingCapacity, rows, bootspace, fuelTankCapacity,
      frontSuspension, rearSuspension, frontbrakeType, rearBrakeType, minTurningRadius,
      steeringType, wheels, spareWheel, frontTyres, rearTyres
    );
    navigate('/feature');
    } 
  };

  return (
    <Grid container spacing={1} justifyContent="center" sx={{ marginTop: '71px', marginBottom: '20px' }} className="KIAbodyLatinbasic_Regular">
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h6" sx={{
                marginBottom: 2, fontWeight: 'bold', color: '#cf2230',
                fontFamily:"KIAbodyLatinbasic_Regular",
                fontSize: { md: '22px', sm: '20px', xs: '17px' }
              }}>
                SPECIFICATIONS
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Typography sx={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', fontWeight: '800' }}>
                Engine & Transmission
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField label="Engine(in cc)" variant="outlined" fullWidth value={engine} onChange={(e) => setEngine(e.target.value)} />
                </Grid>
              
                <Grid item xs={12} sm={4}>
                  <TextField label="Max Power (bhp@rpm)" variant="outlined" fullWidth value={maxPower} onChange={(e) => setMaxPower(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Max Torque (Nm@rpm)" variant="outlined" fullWidth value={maxTorque} onChange={(e) => setMaxTorque(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Mileage (ARAI)" variant="outlined" fullWidth value={mileage} onChange={(e) => setMileage(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" fullWidth >
                    <InputLabel>Drive Train</InputLabel>
                    <Select
                      label="driveTrain"
                      name="driveTrain"
                      value={driveTrain}
                      onChange={(e) => setDriveTrain(e.target.value)}
                    >
                      <MenuItem value="fwd">FWD</MenuItem>
                      <MenuItem value="rwd">RWD</MenuItem>
                      <MenuItem value="awd">AWD</MenuItem>
                      <MenuItem value="4">4X4</MenuItem>
                      <MenuItem value="2">2WD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
               
              </Grid>

              <Typography sx={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', fontWeight: '800' }}>
                Dimensions & Weight
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField label="Length" variant="outlined" fullWidth value={length} onChange={(e) => setLength(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Width" variant="outlined" fullWidth value={width} onChange={(e) => setWidth(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Height" variant="outlined" fullWidth value={height} onChange={(e) => setHeight(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Wheelbase" variant="outlined" fullWidth value={wheelbase} onChange={(e) => setWheelbase(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Ground Clearance" variant="outlined" fullWidth value={groundClearance} onChange={(e) => setGroundClearance(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Kerb Weight" variant="outlined" fullWidth value={kerbWeight} onChange={(e) => setKerbWeight(e.target.value)} />
                </Grid>
              </Grid>

              <Typography sx={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', fontWeight: '800' }}>
                Capacity
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField label="Doors" variant="outlined" fullWidth value={doors} onChange={(e) => setDoors(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Seating Capacity" variant="outlined" fullWidth value={seatingCapacity} onChange={(e) => setSeatingCapacity(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="No of Rows" variant="outlined" fullWidth value={rows} onChange={(e) => setRows(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Bootspace" variant="outlined" fullWidth value={bootspace} onChange={(e) => setBootspace(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Fuel Tank Capacity" variant="outlined" fullWidth value={fuelTankCapacity} onChange={(e) => setFuelTankCapacity(e.target.value)} />
                </Grid>
              </Grid>

              <Typography sx={{ display: 'flex', justifyContent: 'start', marginBottom: '10px', fontWeight: '800' }}>
                Suspensions, Brakes, Steering & Tyres
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField label="Front Suspension" variant="outlined" fullWidth value={frontSuspension} onChange={(e) => setFrontSuspension(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Rear Suspension" variant="outlined" fullWidth value={rearSuspension} onChange={(e) => setRearSuspension(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Front Brake Type" variant="outlined" fullWidth value={frontbrakeType} onChange={(e) => setFrontBrakeType(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Rear Brake Type" variant="outlined" fullWidth value={rearBrakeType} onChange={(e) => setRearBrakeType(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Min Turning Radius" variant="outlined" fullWidth value={minTurningRadius} onChange={(e) => setMinTurningRadius(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Steering Type" variant="outlined" fullWidth value={steeringType} onChange={(e) => setSteeringType(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Wheels" variant="outlined" fullWidth value={wheels} onChange={(e) => setWheels(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Spare Wheel" variant="outlined" fullWidth value={spareWheel} onChange={(e) => setSpareWheel(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Front Tyres" variant="outlined" fullWidth value={frontTyres} onChange={(e) => setFrontTyres(e.target.value)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField label="Rear Tyres" variant="outlined" fullWidth value={rearTyres} onChange={(e) => setRearTyres(e.target.value)} />
                </Grid>
              </Grid>

              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                <Button variant="contained" sx={{ marginRight: '10px', backgroundColor: '#cf2230', '&:hover': { backgroundColor: '#a81825' } }} type="submit">
                  Skip
                </Button>
                <Button variant="contained" sx={{ marginRight: '10px', backgroundColor: '#cf2230', '&:hover': { backgroundColor: '#a81825' } }} type="submit">
                  Next
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
